import * as React from "react";
import { useLocation, useParams } from "react-router-dom";

import CachedIcon from "@mui/icons-material/Cached";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import Snackbar from "@mui/material/Snackbar";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import type { AppWorkoutWithSummary as AppWorkout } from "@volley/data";
import type { AppParameters } from "@volley/shared/app-models";
import type {
    SingleShotConfig,
    SingleShotParameters,
    SingleShotState,
} from "@volley/shared/apps/single-shot-models";

import fetchApi, { logFetchError } from "../../../../../util/fetchApi";
import generateUUID from "../../../../../util/uuid";
import Loading from "../../../../common/Loading";
import { useSelectedSport } from "../../../../common/context/sport";
import { usePhysicsModelContext } from "../../../../hooks/PhysicsModelProvider";
import { useDebugMode } from "../../../../hooks/debugView";
import useRanges from "../../../../hooks/ranges";
import { useLift, LiftModal } from "../../../../hooks/useLift";
import ThrowNowFAB from "../../../Shots/ThrowNowFAB";
import SnackbarTransition from "../../../util/Transitions";
import { mph2mps, mps2mph } from "../../../util/conversions";
import useThrowNow, {
    ThrowingModal,
    ThrowDiagnosticsDrawer,
} from "../../../util/useThrowNow";
import AimAccordion from "../../Accordions/AimAccordion";
import HeightAccordion from "../../Accordions/HeightAccordion";
import ParamsAccordion, {
    SMALLE_MAX_BALLS,
} from "../../Accordions/ParamsAccordion";
import SpeedAccordion from "../../Accordions/SpeedAccordion";
import SpinAccordion from "../../Accordions/SpinAccordion";
import CaptureToast from "../../Shared/CaptureToast";
import PlayAppBar from "../../Shared/PlayAppBar";
import ResetConfirmationDialog from "../../Shared/ResetConfirmation";
import useAppWorkouts, {
    WorkoutPlayWithWorkout,
    generateDefaultFreeplayParams,
    generateDefaultFreeplayWorkout,
} from "../../db";
import LocalWorkouts, {
    updateLocalConfig,
    updateLocalHeight,
    updateLocalParams,
} from "../../localWorkoutState";
import { PlayLocationState } from "../../models";
import useAppWorkoutPlay from "../../useAppWorkoutPlay";
import { AppFetchParams } from "../types";

export async function FreeplayFetch(
    appFetchParams: AppFetchParams,
): Promise<AppWorkout> {
    const {
        physicsModelName,
        ranges: {
            lift: { min },
        },
        selectedSport,
    } = appFetchParams;
    try {
        const result = await fetchApi<WorkoutPlayWithWorkout>(
            `/api/workout-plays/mine/latest?appId=1&sport=${selectedSport}`,
        );
        const cached = LocalWorkouts.get();
        if (
            cached?.appId === 1 &&
            (cached.id === undefined || cached.id === result.workout.id)
        ) {
            const { shot } = result.workout
                .config as unknown as SingleShotConfig;
            const cachedShot = cached.config
                ? (cached.config as Partial<SingleShotConfig>).shot
                : undefined;
            const updated = {
                shot: {
                    ...shot,
                    ...cachedShot,
                },
            };

            result.workout.config = updated;
            result.workout.positionHeight =
                cached.height ?? result.workout.positionHeight;
        }
        return result.workout;
    } catch (ex) {
        logFetchError(ex);
        const defaultWorkout = generateDefaultFreeplayWorkout(
            physicsModelName,
            min,
            selectedSport,
        );
        return defaultWorkout as AppWorkout;
    }
}

export default function AimSpinPlay(): JSX.Element {
    const { id } = useParams<{ id: string }>();
    const idMaybe = React.useMemo(() => parseInt(id ?? "", 10), [id]);

    const [loading, setLoading] = React.useState(true);
    const [loadingError, setLoadingError] = React.useState<string | null>(null);
    const [showReset, setShowReset] = React.useState(false);
    const [showResetDialog, setShowResetDialog] = React.useState(false);

    const { physicsModelName } = usePhysicsModelContext();
    const { selected: selectedSport } = useSelectedSport();
    const { enabled: diagnosticsEnabled } = useDebugMode();
    const [expanded, setExpanded] = React.useState<string | false>("Height");
    const [workout, setWorkout] = React.useState<AppWorkout | null>(null);
    const [shouldSave, setShouldSave] = React.useState(false);
    const [playClicked, setPlayClicked] = React.useState(false);

    const [selectedInterval, setSelectedInterval] = React.useState(4);
    const [selectedShotCount, setSelectedShotCount] = React.useState(60);

    const { pan: panRange, tilt: tiltRange, lift } = useRanges();

    // When reloading, the height is not always ready (waiting on status update), so default
    // to the safeHeight from useLift as a reasonable default - only do this when the control mounts
    const {
        error: liftError,
        stop: stopLift,
        height,
        safeHeight,
        checkForLift,
    } = useLift();
    const initialHeight = React.useMemo(
        () => (height === 0 ? safeHeight : height),
        [height, safeHeight],
    );

    const [selectedHeight, setSelectedHeight] = React.useState(initialHeight);
    const [selectedSpeed, setSelectedSpeed] = React.useState(15);
    const [selectedSpeedVariation, setSelectedSpeedVariation] =
        React.useState(0);
    const [selectedSpin, setSelectedSpin] = React.useState(0);
    const [selectedSpinIntensity, setSelectedSpinIntensity] = React.useState(0);
    const [selectedAim, setSelectedAim] = React.useState(0);
    const [selectedAimVariation, setSelectedAimVariation] = React.useState(0);
    const [selectedArc, setSelectedArc] = React.useState(0);
    const [selectedArcVariation, setSelectedArcVariation] = React.useState(0);

    const userParams = React.useMemo(
        () => ({
            selectedHeight,
            selectedSpeed,
            selectedSpeedVariation,
            selectedSpin,
            selectedSpinIntensity,
            selectedAim,
            selectedAimVariation,
            selectedArc,
            selectedArcVariation,
        }),
        [
            selectedHeight,
            selectedSpeed,
            selectedSpeedVariation,
            selectedSpin,
            selectedSpinIntensity,
            selectedAim,
            selectedAimVariation,
            selectedArc,
            selectedArcVariation,
        ],
    );

    const location = useLocation();

    const {
        addWorkout,
        loadSingleShot,
        loading: workoutLoading,
        error: saveError,
    } = useAppWorkouts();

    React.useEffect(() => {
        function loadWorkout(wId?: number) {
            const rId = Number.isNaN(wId) ? undefined : wId;
            return loadSingleShot(rId);
        }

        if (!workoutLoading && workout === null) {
            setLoading(true);
            loadWorkout(idMaybe)
                .then((w) => {
                    if (w) {
                        setWorkout(w.workout);
                        setSelectedInterval(
                            (w.params as unknown as SingleShotParameters)
                                .intervalOverride,
                        );
                        setSelectedShotCount(
                            (w.params as unknown as SingleShotParameters)
                                .numberOfBalls,
                        );
                        setLoadingError(null);
                        setShowReset(w.source !== "default");

                        // we need to save a default workout before we can play it
                        // otherwise it will fail to play because of server-side validation
                        if (w.source === "default") {
                            setShouldSave(true);
                        }
                    }
                })
                .catch((e: unknown) => {
                    setLoadingError("Unable to load workout.");
                    logFetchError(e, "Failed to load saved freeplay");
                })
                .finally(() => setLoading(false));
        }
    }, [idMaybe, loading, workout, loadSingleShot, workoutLoading]);

    const parameters: AppParameters = React.useMemo(() => {
        const updated = {
            numberOfBalls: selectedShotCount,
            intervalOverride: selectedInterval,
            initialDelay: 1,
        };

        return updated;
    }, [selectedShotCount, selectedInterval]);

    React.useEffect(() => {
        if (workout) {
            const config = workout.config as unknown as SingleShotConfig;
            setSelectedHeight(workout.positionHeight);
            setSelectedSpeed(mps2mph(config.shot.launchSpeed));
            setSelectedSpeedVariation(config.shot.launchSpeedVariation);
            setSelectedSpin(config.shot.spinDirection ?? 0);
            setSelectedSpinIntensity(config.shot.spinLevel ?? 0);
            setSelectedAim(config.shot.pan);
            setSelectedAimVariation(config.shot.panVariation);
            setSelectedArc(config.shot.tilt);
            setSelectedArcVariation(config.shot.tiltVariation);
        }
    }, [workout]);

    const throwState = useThrowNow({
        launchSpeed: mph2mps(selectedSpeed),
        pan: selectedAim,
        spinAxis: selectedSpin,
        spinLevel: selectedSpinIntensity,
        tilt: selectedArc,
        height: selectedHeight,
    });

    const {
        canThrow,
        throwing,
        maxSpinLevel,
        makeShot,
        error: throwError,
    } = throwState;

    const {
        pause,
        captureVideo,
        captureDisabled,
        captureStatus,
        playState,
        requestError,
        start,
        stop: stopWorkout,
        workoutState,
        statusText,
        playDisabled,
        pauseDisabled,
        playInitiated,
    } = useAppWorkoutPlay({
        workout,
        parameters,
    });

    const resetToDefault = React.useCallback(() => {
        if (playState !== "stopped") {
            void stopWorkout();
        }
        const defaultWorkout = generateDefaultFreeplayWorkout(
            physicsModelName,
            lift.min,
            selectedSport,
        );
        const defaultParams = generateDefaultFreeplayParams();
        setWorkout(defaultWorkout as AppWorkout);
        setSelectedInterval(defaultParams.intervalOverride);
        setSelectedShotCount(defaultParams.numberOfBalls);
        setShowReset(false);
        setShouldSave(true);
        setShowResetDialog(false);
        LocalWorkouts.clear();
    }, [physicsModelName, lift.min, stopWorkout, playState, selectedSport]);

    const liftModalText = React.useMemo(() => {
        if (throwing) {
            return "The trainer arm is moving to the selected height to throw the test.";
        }

        return "The trainer arm is moving to the specified height for your workout.";
    }, [throwing]);

    const freeplayState = workoutState as unknown as SingleShotState | null;
    const ballCount = React.useMemo(
        () =>
            freeplayState?.currentShotNumber
                ? freeplayState.currentShotNumber
                : 0,
        [freeplayState],
    );
    const totalBalls =
        (parameters.numberOfBalls as number) === SMALLE_MAX_BALLS
            ? "All"
            : (parameters.numberOfBalls as string);
    const ballSummary = playInitiated ? `${ballCount} of ${totalBalls}` : "";

    const saveWorkout = React.useCallback(async () => {
        const {
            selectedAim: pan,
            selectedAimVariation: panVariation,
            selectedArc: tilt,
            selectedArcVariation: tiltVariation,
            selectedHeight: positionHeight,
            selectedSpeed: launchSpeed,
            selectedSpeedVariation: launchSpeedVariation,
            selectedSpin: spinDirection,
            selectedSpinIntensity: spinLevel,
        } = userParams;

        if (workout === null || shouldSave) {
            const newWorkout = await addWorkout({
                appId: 1,
                appName: "FreePlay",
                config: {
                    shot: {
                        id: generateUUID(),
                        pan,
                        panVariation,
                        tilt,
                        tiltVariation,
                        launchSpeed: mph2mps(launchSpeed),
                        launchSpeedVariation,
                        spinDirection,
                        spinLevel,
                    },
                },
                physicsModelName,
                description: "Generated Free Play Workout",
                name: "Free Play",
                overview: "",
                positionHeight,
                positionX: 0,
                positionY: 0,
                positionYaw: 0,
                state: "BUILD",
                contentProviderId: null,
                copiedFromAppWorkoutId: null,
                extendedData: null,
                sport: { name: selectedSport },
            });

            if (newWorkout !== null) {
                setWorkout(newWorkout);
                setShouldSave(false);
            }
        }
    }, [
        addWorkout,
        shouldSave,
        physicsModelName,
        selectedSport,
        userParams,
        workout,
    ]);

    const handlePlayClicked = React.useCallback(async () => {
        await saveWorkout();
        LocalWorkouts.clear();
        setPlayClicked(true);
    }, [saveWorkout]);

    React.useEffect(() => {
        if (workout && playClicked) {
            setPlayClicked(false);
            checkForLift();
            setExpanded(false);
            void start();
        }
    }, [workout, playClicked, checkForLift, start]);

    const handleLiftStop = React.useCallback(async () => {
        await stopLift();
        if (playState !== "stopped") {
            await stopWorkout();
        }
    }, [stopLift, stopWorkout, playState]);

    const handlePanelChange =
        (panel: string) => (_: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };

    const hasError = React.useMemo(
        () =>
            requestError !== null ||
            loadingError !== null ||
            liftError !== null ||
            saveError !== null,
        [loadingError, requestError, liftError, saveError],
    );

    React.useEffect(() => {
        if (hasError || expanded === false) {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: "smooth",
            });
        }
    }, [hasError, expanded]);

    React.useEffect(() => {
        const resume = (location?.state as PlayLocationState)?.resume;
        if (resume && playState === "playing") {
            setExpanded(false);
        }
    }, [location?.state, playState]);

    React.useEffect(() => {
        if (playDisabled) {
            window.scrollTo({
                behavior: "smooth",
                top: 0,
            });
            setExpanded(false);
        }
    }, [playDisabled]);

    if (loading) {
        return <Loading />;
    }

    return (
        <Box
            component="div"
            sx={{
                minHeight: hasError ? "944px" : "880px",
            }}
        >
            <ThrowDiagnosticsDrawer open={diagnosticsEnabled} {...throwState} />
            {hasError && (
                <Snackbar
                    open
                    anchorOrigin={{ vertical: "top", horizontal: "center" }}
                    TransitionComponent={SnackbarTransition}
                    sx={{ top: 60 }}
                >
                    <Alert severity="warning" variant="filled">
                        <AlertTitle>
                            {throwError
                                ? "Invalid Throw Configuration"
                                : "Error"}
                        </AlertTitle>
                        {requestError ||
                            liftError ||
                            throwError ||
                            loadingError}
                    </Alert>
                </Snackbar>
            )}
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <Typography variant="h3" color="primary.main" gutterBottom>
                    {statusText}
                </Typography>
                {showReset && !playDisabled && (
                    <IconButton
                        onClick={() => setShowResetDialog(true)}
                        color="primary"
                    >
                        <CachedIcon fontSize="large" />
                    </IconButton>
                )}
            </Stack>
            <HeightAccordion
                expanded={expanded === "Height"}
                disabled={playDisabled}
                onChange={handlePanelChange("Height")}
                onHeightChanged={(val) => {
                    setShouldSave(true);
                    setShowReset(true);
                    updateLocalHeight(val, 1, workout?.id);
                    setSelectedHeight(val);
                }}
                selectedHeight={selectedHeight}
            />
            <SpeedAccordion
                expanded={expanded === "Speed"}
                disabled={playDisabled}
                onChange={handlePanelChange("Speed")}
                selectedSpeed={selectedSpeed}
                onSpeedChanged={(val) => {
                    setShouldSave(true);
                    setShowReset(true);
                    updateLocalConfig(
                        { launchSpeed: mph2mps(val) },
                        1,
                        workout?.id,
                    );
                    setSelectedSpeed(val);
                }}
                selectedSpeedVariation={selectedSpeedVariation}
                onSpeedVariationChanged={(val) => {
                    setShouldSave(true);
                    setShowReset(true);
                    updateLocalConfig(
                        { launchSpeedVariation: val },
                        1,
                        workout?.id,
                    );
                    setSelectedSpeedVariation(val);
                }}
            />
            <SpinAccordion
                expanded={expanded === "SpeedSpin"}
                disabled={playDisabled}
                onChange={handlePanelChange("SpeedSpin")}
                selectedSpin={selectedSpin}
                onSpinChanged={(val) => {
                    setShouldSave(true);
                    setShowReset(true);
                    updateLocalConfig({ spinDirection: val }, 1, workout?.id);
                    setSelectedSpin(val);
                }}
                selectedSpinIntensity={selectedSpinIntensity}
                onSpinIntensityChanged={(val) => {
                    setShowReset(true);
                    updateLocalConfig({ spinLevel: val }, 1, workout?.id);
                    setShouldSave(true);
                    setSelectedSpinIntensity(val);
                }}
                maxSpinLevel={maxSpinLevel}
            />
            <AimAccordion
                expanded={expanded === "Arc"}
                disabled={playDisabled}
                onChange={handlePanelChange("Arc")}
                aimRange={tiltRange}
                label="Arc"
                onAimChanged={(val) => {
                    setShowReset(true);
                    setShouldSave(true);
                    updateLocalConfig({ tilt: val }, 1, workout?.id);
                    setSelectedArc(val);
                }}
                selectedAim={selectedArc}
                onAimVariationChanged={(val) => {
                    setShouldSave(true);
                    updateLocalConfig({ tiltVariation: val }, 1, workout?.id);
                    setSelectedArcVariation(val);
                }}
                selectedAimVariation={selectedArcVariation}
            />
            <AimAccordion
                expanded={expanded === "Aim"}
                disabled={playDisabled}
                onChange={handlePanelChange("Aim")}
                aimRange={panRange}
                label="Aim"
                onAimChanged={(val) => {
                    setShowReset(true);
                    setShouldSave(true);
                    updateLocalConfig({ pan: val }, 1, workout?.id);
                    setSelectedAim(val);
                }}
                selectedAim={selectedAim}
                onAimVariationChanged={(val) => {
                    setShowReset(true);
                    setShouldSave(true);
                    updateLocalConfig({ panVariation: val }, 1, workout?.id);
                    setSelectedAimVariation(val);
                }}
                selectedAimVariation={selectedAimVariation}
            />
            <ParamsAccordion
                expanded={expanded === "Params"}
                disabled={playDisabled}
                onChange={handlePanelChange("Params")}
                selectedInterval={selectedInterval}
                selectedShotCount={selectedShotCount}
                onIntervalChanged={(val) => {
                    if (playState !== "stopped") {
                        void stopWorkout();
                    }
                    setShowReset(true);
                    updateLocalParams(
                        { intervalOverride: val },
                        1,
                        workout?.id,
                    );
                    setSelectedInterval(val);
                }}
                onShotCountChanged={(val) => {
                    if (playState !== "stopped") {
                        void stopWorkout();
                    }
                    setShowReset(true);
                    updateLocalParams({ numberOfBalls: val }, 1, workout?.id);
                    setSelectedShotCount(val);
                }}
                copyLevel={() => {}}
                setLevel={() => {}}
            />
            <PlayAppBar
                onPlayClicked={() => handlePlayClicked()}
                onPauseClicked={() => pause()}
                pauseDisabled={pauseDisabled}
                playDisabled={playDisabled || !canThrow}
                showRecord
                onRecordClicked={() => captureVideo()}
                playState={playState}
                playSummary={ballSummary}
                recordDisabled={captureDisabled}
                recordingStatus={captureStatus}
            />
            <ThrowingModal open={throwing} />
            <LiftModal
                stop={handleLiftStop}
                targetHeight={selectedHeight}
                message={liftModalText}
            />
            <ResetConfirmationDialog
                open={showResetDialog}
                onCancel={() => setShowResetDialog(false)}
                onConfirm={() => resetToDefault()}
            />
            <CaptureToast captureStatus={captureStatus} />
            {/*
            This will be formally implemented for 2.15 release
            <OtherWorkoutWarning workoutUrlAction={otherWorkout} />
            */}
            <Loading open={workoutLoading} />
            {expanded !== "Params" && playState !== "playing" && (
                <ThrowNowFAB
                    disabled={!canThrow}
                    onClick={() => makeShot(selectedHeight)}
                    side="fabBottomRight"
                />
            )}
        </Box>
    );
}
