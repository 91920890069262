import * as React from "react";

import HelpIcon from "@mui/icons-material/Help";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";

import useIntercom from "../hooks/useIntercom";

import useDialog from "./useDialog";

export default function TiltCalibrationFailureError(): JSX.Element {
    const { setDialogType, setTiltCalibrationDismissed } = useDialog();
    const intercom = useIntercom();

    return (
        <>
            <DialogTitle variant="h4" sx={{ p: 2 }} color="primary.main">
                Ball Stuck
            </DialogTitle>
            <DialogContent dividers>
                There was an error while trying to prepare the trainer to remove
                the ball.
            </DialogContent>
            <DialogActions>
                <Stack width="100%">
                    <Button
                        variant="contained"
                        color="secondary"
                        fullWidth
                        onClick={() => {
                            setTiltCalibrationDismissed(true);
                            setDialogType("Device");
                        }}
                        sx={{ mb: 2 }}
                    >
                        Close
                    </Button>
                    <Button
                        onClick={() => {
                            intercom.newMessage();
                        }}
                        startIcon={<HelpIcon />}
                    >
                        Need help? Chat with support.
                    </Button>
                </Stack>
            </DialogActions>
        </>
    );
}
