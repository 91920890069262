import type { AppWorkoutWithSummary as AppWorkout, Tag } from "@volley/data";
import type {
    SingleShotConfig,
    SingleShotShot,
} from "@volley/shared/apps/single-shot-models";
import type { JSONObject } from "@volley/shared/common-models";

import type {
    CoordLike,
    CoordWithSys,
    PositionLike,
} from "../../../../../util/position-types";
import generateUUID from "../../../../../util/uuid";

export const DEFAULT_SHOT: SingleShotShot = {
    id: generateUUID(),
    launchSpeed: 7,
    launchSpeedVariation: 0,
    pan: 0,
    panVariation: 0,
    spinDirection: 0,
    spinLevel: 0,
    tilt: 0,
    tiltVariation: 0,
};

export function createNewSingleShot(
    userEmail: string,
    physicsModelName: string,
    sport: string,
    position: CoordWithSys,
    sportId: number,
    height: number,
): WorkoutForm {
    return {
        _count: { favorites: 0, workoutPlays: 0 },
        appId: 5,
        appName: "Single Shot",
        config: {
            playerPosition: { x: 0, y: 0 },
            shot: DEFAULT_SHOT,
        } as unknown as JSONObject,
        contentProviderId: null,
        contentProvider: null,
        description: "",
        id: 0,
        name: "",
        copiedFromAppWorkoutId: null,
        createdBy: userEmail,
        createdAt: new Date(),
        extendedData: null,
        isDirty: true,
        isOwner: true,
        isFavorite: false,
        overview: "",
        physicsModelName,
        positionHeight: height,
        positionX: position.x,
        positionY: position.y,
        positionYaw: 0,
        sport: { name: sport },
        sportId,
        state: "BUILD",
        tags: null,
        updatedAt: new Date(),
        updatedBy: userEmail,
        workoutTags: [],
    };
}

export type WorkoutForm = AppWorkout & {
    isDirty: boolean;
    isOwner: boolean;
    isFavorite: boolean;
    tags: Record<number, Tag[]> | null;
};

export type WorkoutAction =
    | { type: "name"; value: string }
    | { type: "description"; value: string }
    | { type: "trainerPosition"; value: PositionLike }
    | { type: "playerPosition"; value: CoordLike }
    | { type: "positionHeight"; value: number }
    | { type: "contentProviderId"; value: number | null }
    | { type: "tags"; value: Record<number, Tag[]> }
    | { type: "favorite"; value: boolean }
    | { type: "updateConfig"; value: SingleShotConfig }
    | { type: "setDirty"; value: boolean }
    | {
          type: "set";
          value: (AppWorkout & { isOwner: boolean }) | null;
      };

function isInitialized(
    _state: WorkoutForm | null,
    action: WorkoutAction,
): _state is WorkoutForm {
    return action.type === "set";
}

export function reducer(
    state: WorkoutForm | null,
    action: WorkoutAction,
): WorkoutForm | null {
    if (!isInitialized(state, action) && !state) return null;
    switch (action.type) {
        case "name":
            return { ...state, isDirty: true, name: action.value };
        case "description":
            return { ...state, isDirty: true, description: action.value };
        case "trainerPosition":
            return {
                ...state,
                isDirty: true,
                positionX: action.value.x,
                positionY: action.value.y,
                positionYaw: action.value.yaw,
            };
        case "playerPosition": {
            const config: SingleShotConfig = {
                ...(state.config as unknown as SingleShotConfig),
                playerPosition: action.value,
            };
            return {
                ...state,
                isDirty: true,
                config: config as unknown as JSONObject,
            };
        }
        case "positionHeight":
            return { ...state, isDirty: true, positionHeight: action.value };
        case "contentProviderId":
            return { ...state, isDirty: true, contentProviderId: action.value };
        case "tags":
            return {
                ...state,
                isDirty: state.tags !== null,
                tags: action.value,
            };
        case "favorite":
            return { ...state, isFavorite: action.value };
        case "updateConfig":
            return {
                ...state,
                config: action.value as unknown as JSONObject,
                isDirty: true,
            };
        case "setDirty":
            return { ...state, isDirty: action.value };
        case "set":
            return action.value
                ? {
                      ...action.value,
                      isDirty: action.value.id === 0,
                      tags: null,
                      isFavorite: action.value._count?.favorites > 0,
                  }
                : null;
        default:
            throw new Error("Invalid Action to Perform on a Workout");
    }
}
