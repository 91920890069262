import * as React from "react";

import { useSelectedSport } from "../../../../common/context/sport";
import CardWrapper from "../../Shared/CardWrapper";
import { AppListItemProps } from "../types";

export default function ResponsiveCard({
    onClick,
    order,
}: AppListItemProps): JSX.Element | null {
    const { selected } = useSelectedSport();
    return (
        <CardWrapper
            onClick={onClick}
            order={order}
            workout={{
                appId: 2,
                globalPlayCount: 0,
                id: 1,
                name: "Responsive Play",
                provider: null,
                isFavorite: false,
                lastUserPlay: null,
                overview: "",
                sportName: selected,
                userPlayCount: 0,
            }}
        />
    );
}
