import * as React from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Accordion, { AccordionProps } from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { capitalizeFirstLetter } from "../../../../util/text";
import { useLift } from "../../../hooks/useLift";
import AccordionSlider from "../Shared/AccordionSlider";
import {
    FreePlayKnownHeight,
    KnownHeightsAtom,
    KnownHeightDescriptions,
} from "../apps/1-freeplay/context";

interface HeightAccordionProps extends Omit<AccordionProps, "children"> {
    selectedHeight: number;
    onHeightChanged: (value: number) => void;
    moveToHeight?: () => void;
}

function generateHeightMarks() {
    return Object.keys(KnownHeightsAtom).map((k) => {
        const value = KnownHeightsAtom[k as FreePlayKnownHeight];
        const label = capitalizeFirstLetter(k);

        return {
            value,
            label,
        };
    });
}

function exactHeightToWord(height: number) {
    const maybe = Object.keys(KnownHeightsAtom).find((k) => {
        const knownHeight = KnownHeightsAtom[k as FreePlayKnownHeight];
        const min = knownHeight - 8;
        const max = knownHeight + 10;
        if (height >= min && height <= max) {
            return k;
        }

        return undefined;
    });

    return maybe === undefined ? "Not Set" : maybe;
}

function exactHeightToDescription(height: number) {
    const match = Object.keys(KnownHeightDescriptions).find((k) => {
        const knownHeight = KnownHeightsAtom[k as FreePlayKnownHeight];
        const min = knownHeight - 8;
        const max = knownHeight + 10;
        if (height >= min && height <= max) {
            return true;
        }

        return undefined;
    });

    const result = KnownHeightDescriptions[match as FreePlayKnownHeight];
    return result;
}

export default function HeightAccordion({
    selectedHeight,
    onHeightChanged,
    moveToHeight,
    ...props
}: HeightAccordionProps): JSX.Element {
    const { liftRange } = useLift();
    const [userHeight, setUserHeight] = React.useState(selectedHeight);
    const heightMarks = React.useMemo(() => generateHeightMarks(), []);
    const userHeightText = React.useMemo(
        () => exactHeightToWord(userHeight),
        [userHeight],
    );
    const userHeightDescription = React.useMemo(
        () => exactHeightToDescription(userHeight),
        [userHeight],
    );

    React.useEffect(() => {
        setUserHeight(selectedHeight);
    }, [selectedHeight]);

    return (
        <Accordion {...props}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                {!props.expanded && (
                    <Box>
                        <Typography variant="h4" color="primary.main">
                            Height
                        </Typography>
                        <Typography variant="h3" color="primary.main">
                            {userHeightText}
                        </Typography>
                    </Box>
                )}
                {props.expanded && (
                    <Typography variant="h3" color="primary.main">
                        Head Height
                    </Typography>
                )}
            </AccordionSummary>
            <AccordionDetails>
                <Stack spacing={3}>
                    <Box
                        sx={{
                            minHeight: "42px",
                        }}
                    >
                        <Typography variant="body2">
                            {userHeightDescription}
                        </Typography>
                    </Box>
                    <Box>
                        <AccordionSlider
                            min={liftRange.min}
                            max={liftRange.max}
                            marks={heightMarks}
                            value={userHeight}
                            onChange={(_, v) => setUserHeight(v as number)}
                            onChangeCommitted={(_, v) =>
                                onHeightChanged(v as number)
                            }
                            sx={{
                                display: "table",
                                margin: "0px auto 30px auto",
                                maxWidth: "90%",
                                color: "primary.light",
                                ".MuiSlider-track": {
                                    color: "primary.light",
                                },
                            }}
                        />
                    </Box>
                    {moveToHeight !== undefined && (
                        <Button
                            fullWidth
                            color="secondary"
                            variant="contained"
                            onClick={() => moveToHeight()}
                        >
                            Move Head to Height
                        </Button>
                    )}
                </Stack>
            </AccordionDetails>
        </Accordion>
    );
}
