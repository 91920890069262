import * as React from "react";

import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";

import trainerLocationImg from "../../../../../static/img/sv-trainer-location.jpg";
import CloseableDialogTitle from "../../../../common/CloseableDialogTitle";

interface Props {
    instructionDialogOpen: boolean;
    setInstructionDialogOpen: (open: boolean) => void;
}

export default function InstructionDialog({
    instructionDialogOpen,
    setInstructionDialogOpen,
}: Props) {
    return (
        <Dialog open={instructionDialogOpen}>
            <CloseableDialogTitle
                onClose={() => setInstructionDialogOpen(false)}
            />
            <DialogContent>
                <Box component="div" mb={3}>
                    <Typography variant="h4" mb={1}>
                        Objective
                    </Typography>
                    <Typography>
                        In this game, you will practice serving the ball and the
                        trainer will use the camera to determine if it was in or
                        out. At the conclusion of your serves, view the map of
                        where the serves landed. Press play on the previous
                        screen when ready.
                    </Typography>
                </Box>
                <Box component="div" mb={3}>
                    <Typography variant="h4" mb={1}>
                        Setup
                    </Typography>
                    <Typography>
                        The trainer must be placed in the location shown below.
                        Front axle is centered where the singles line meets the
                        baseline. Then angle the trainer so that it points
                        towards the center of the net.
                    </Typography>
                </Box>
                <Box
                    component="img"
                    src={trainerLocationImg as string}
                    alt="Trainer location"
                    mb={3}
                    sx={{ width: "100%", mb: 2 }}
                />
            </DialogContent>
        </Dialog>
    );
}
