/* eslint-disable import/no-cycle */
import CardWrapper from "../Shared/CardWrapper";
import ListItemWrapper from "../Shared/ListItemWrapper";
import NotAvailable from "../Shared/NotAvailable";

import AimSpinPlay from "./1-freeplay/AimSpinPlay";
import ServeAndVolleyRoot from "./10-serve-and-volley/serveAndVolley";
import ModuleCard from "./11-module/Card";
import ModuleIcon from "./11-module/Icon";
import ModuleEdit from "./11-module/ModuleEdit/ModuleEdit";
import ModulePlay from "./11-module/ModulePlay";
import ServeChallenge from "./12-serve-challenge";
import BaselineDepthRoot from "./13-baseline-depth";
import ResponsiveCard from "./2-responsive/Card";
import ResponsivePlayApp from "./2-responsive/ResponsivePlayApp";
import LobCard from "./3-lobs/Card";
import LobApp from "./3-lobs/LobApp2";
import MultiShotCard from "./4-multi-shot/Card";
import MultiShotEdit from "./4-multi-shot/Edit";
import MultiShotIcon from "./4-multi-shot/Icon";
import MultiShotPlay from "./4-multi-shot/PlayScreen";
import SingleShotCard from "./5-single-shot/Card";
import SingleShotIcon from "./5-single-shot/Icon";
import SingleShot from "./5-single-shot/SingleShot";
import SharedCard from "./7-shared-workout/Card";
import SharedListItem from "./7-shared-workout/ListItem";
import PickleballGame from "./8-pickleball-game/pickleBallGame";
import MultiLevelCard from "./9-multi-level/Card";
import MultiLevelEdit from "./9-multi-level/Edit";
import MultiLevelIcon from "./9-multi-level/Icon";
import MultiLevelPlayScreen from "./9-multi-level/PlayScreen";
import {
    getDefaultMultiLevelConfig,
    getDefaultMultiLevelParams,
} from "./9-multi-level/defaults";
import HeroWrapper from "./shared/Hero";
import type { AppDefinition } from "./types";

export const ValidAppIds = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13] as const;
export type AppId = (typeof ValidAppIds)[number];

const apps: Record<AppId, AppDefinition> = {
    1: {
        id: 1,
        name: "Freeplay",
        EditComponent: AimSpinPlay,
        ListComponent: ListItemWrapper,
        PlayComponent: AimSpinPlay,
        CardComponent: SingleShotCard,
        HeroComponent: SingleShotCard,
        getDefaultConfig: () => ({}),
        getDefaultParams: () => ({}),
    },
    2: {
        id: 2,
        name: "Responsive Play",
        EditComponent: NotAvailable,
        PlayComponent: ResponsivePlayApp,
        ListComponent: ListItemWrapper,
        CardComponent: ResponsiveCard,
        HeroComponent: HeroWrapper,
        getDefaultConfig: () => ({}),
        getDefaultParams: () => ({}),
    },
    3: {
        id: 3,
        name: "Point-and-Click Lob App",
        EditComponent: NotAvailable,
        PlayComponent: LobApp,
        ListComponent: ListItemWrapper,
        CardComponent: LobCard,
        HeroComponent: HeroWrapper,
        getDefaultConfig: () => ({}),
        getDefaultParams: () => ({}),
    },
    4: {
        id: 4,
        name: "Curated Workout",
        EditComponent: MultiShotEdit,
        ListComponent: ListItemWrapper,
        PlayComponent: MultiShotPlay,
        CardComponent: MultiShotCard,
        HeroComponent: HeroWrapper,
        IconComponent: MultiShotIcon,
        getDefaultConfig: () => ({}),
        getDefaultParams: () => ({}),
    },
    5: {
        id: 5,
        name: "Single Shot Skills",
        EditComponent: SingleShot,
        ListComponent: ListItemWrapper,
        PlayComponent: SingleShot,
        CardComponent: SingleShotCard,
        HeroComponent: HeroWrapper,
        IconComponent: SingleShotIcon,
        getDefaultConfig: () => ({}),
        getDefaultParams: () => ({}),
    },
    6: {
        id: 6,
        name: "User Created Workout",
        EditComponent: MultiShotEdit,
        ListComponent: ListItemWrapper,
        PlayComponent: MultiShotPlay,
        CardComponent: MultiShotCard,
        HeroComponent: HeroWrapper,
        IconComponent: MultiShotIcon,
        getDefaultConfig: () => ({}),
        getDefaultParams: () => ({}),
    },
    7: {
        id: 7,
        name: "Shared Workout",
        EditComponent: NotAvailable,
        ListComponent: SharedListItem,
        CardComponent: SharedCard,
        PlayComponent: NotAvailable,
        HeroComponent: HeroWrapper,
        getDefaultConfig: () => ({}),
        getDefaultParams: () => ({}),
    },
    8: {
        id: 8,
        name: "Pickleball Game",
        EditComponent: NotAvailable,
        ListComponent: ListItemWrapper,
        PlayComponent: PickleballGame,
        CardComponent: CardWrapper,
        HeroComponent: HeroWrapper,
        getDefaultConfig: () => ({}),
        getDefaultParams: () => ({}),
    },
    9: {
        id: 9,
        name: "Multi-Level Workout",
        EditComponent: MultiLevelEdit,
        ListComponent: ListItemWrapper,
        PlayComponent: MultiLevelPlayScreen,
        CardComponent: MultiLevelCard,
        HeroComponent: HeroWrapper,
        IconComponent: MultiLevelIcon,
        getDefaultConfig: getDefaultMultiLevelConfig,
        getDefaultParams: getDefaultMultiLevelParams,
    },
    10: {
        id: 10,
        name: "Serve and Volley",
        EditComponent: NotAvailable,
        ListComponent: ListItemWrapper,
        PlayComponent: ServeAndVolleyRoot,
        CardComponent: CardWrapper,
        HeroComponent: HeroWrapper,
        getDefaultConfig: () => ({}),
        getDefaultParams: () => ({}),
    },
    11: {
        id: 11,
        name: "Module",
        EditComponent: ModuleEdit,
        ListComponent: ListItemWrapper,
        PlayComponent: ModulePlay,
        CardComponent: ModuleCard,
        HeroComponent: HeroWrapper,
        IconComponent: ModuleIcon,
        getDefaultConfig: () => ({}),
        getDefaultParams: () => ({}),
    },
    12: {
        id: 12,
        name: "Serve Challenge",
        EditComponent: NotAvailable,
        ListComponent: NotAvailable,
        PlayComponent: ServeChallenge,
        CardComponent: CardWrapper,
        HeroComponent: HeroWrapper,
        getDefaultConfig: () => ({}),
        getDefaultParams: () => ({}),
    },
    13: {
        id: 13,
        name: "Baseline Depth",
        EditComponent: NotAvailable,
        ListComponent: ListItemWrapper,
        PlayComponent: BaselineDepthRoot,
        CardComponent: CardWrapper,
        HeroComponent: HeroWrapper,
        getDefaultConfig: () => ({}),
        getDefaultParams: () => ({}),
    },
};

export function getApp(id: AppId): AppDefinition {
    return apps[id];
}

export function findApp(id: number): AppDefinition | null {
    return apps[id as AppId] ?? null;
}

export function getApps(): AppDefinition[] {
    return Object.values(apps);
}

export function getAppId(idMaybe: string | undefined): AppId | null {
    if (idMaybe) {
        const parsed = parseInt(idMaybe, 10);
        if (!Number.isNaN(parsed)) {
            if (ValidAppIds.includes(parsed as AppId)) {
                return parsed as AppId;
            }
        }
    }

    return null;
}
