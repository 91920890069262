import * as React from "react";

import CardWrapper from "../../Shared/CardWrapper";
import { AppListItemProps } from "../types";

export default function SingleShotCard({
    onClick,
    workout,
    order,
}: AppListItemProps): JSX.Element | null {
    return <CardWrapper onClick={onClick} workout={workout} order={order} />;
}
