import * as React from "react";

import HelpIcon from "@mui/icons-material/Help";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import CloseableDialogTitle from "../common/CloseableDialogTitle";
import useIntercom from "../hooks/useIntercom";

import useDialog from "./useDialog";

interface Props {
    onDone: () => void;
}

export default function ReportIssueError({ onDone }: Props): JSX.Element {
    const { setDialogType } = useDialog();
    const intercom = useIntercom();
    return (
        <>
            <CloseableDialogTitle onClose={() => setDialogType("Device")}>
                Problem Submitting Issue
            </CloseableDialogTitle>
            <DialogContent>
                We&apos;ve encountered an error submitting your issue. Please
                click &quot;Contact Help&quot; below to chat with support.
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        intercom.newMessage();
                        onDone();
                    }}
                    startIcon={<HelpIcon />}
                    variant="contained"
                    color="secondary"
                >
                    Contact Help
                </Button>
            </DialogActions>
        </>
    );
}
