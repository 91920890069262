import * as React from "react";

import HelpIcon from "@mui/icons-material/Help";
import LoadingButton from "@mui/lab/LoadingButton";
import { DialogTitle } from "@mui/material";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { pairedFetchApi, logFetchError } from "../../util/fetchApi";
import cdnUrl from "../CampaignClubs/cdnUrl";
import CloseableDialogTitle from "../common/CloseableDialogTitle";
import { useStatus } from "../hooks/status";
import useIntercom from "../hooks/useIntercom";

import useDialog from "./useDialog";

export interface BallJamPreambleProps {
    onPoweredDown: () => void;
    onError: () => void;
}
export default function TiltCalibrationFailurePreamble({
    onPoweredDown,
    onError,
}: BallJamPreambleProps): JSX.Element {
    const { setDialogType, setTiltCalibrationDismissed } = useDialog();
    const { status } = useStatus();
    const [loweringPower, setLoweringPower] = React.useState(false);
    const intercom = useIntercom();

    const onClearLowerPower = React.useCallback(async () => {
        setLoweringPower(true);
        try {
            await pairedFetchApi(status?.clientId, "/api/clear-fault", "POST");
            await pairedFetchApi(
                status?.clientId,
                "/api/low-power-clear-jam",
                "POST",
            );
        } catch (e) {
            logFetchError(
                e,
                "Failed to lower power level from BalljamPreamble",
            );
            onError();
        }
    }, [status?.clientId, onError]);

    React.useEffect(() => {
        if (
            loweringPower &&
            status?.trainer.powerState === "L3" &&
            (status?.trainer.state === "IDLE" ||
                status?.trainer.state === "UNCALIBRATED")
        ) {
            onPoweredDown();
        }
    }, [
        loweringPower,
        onPoweredDown,
        status?.trainer.powerState,
        status?.trainer.state,
    ]);

    return (
        <>
            {loweringPower && (
                <DialogTitle sx={{ p: 2 }} color="primary.main" variant="h4">
                    Ball Stuck
                </DialogTitle>
            )}
            {!loweringPower && (
                <CloseableDialogTitle
                    variant="h4"
                    onClose={() => {
                        setTiltCalibrationDismissed(true);
                        setDialogType("Device");
                    }}
                >
                    Ball Stuck
                </CloseableDialogTitle>
            )}
            <DialogContent dividers>
                <Typography gutterBottom>
                    There is likely a ball stuck in the location circled in red
                    below. Press Proceed when you are ready to remove the ball.
                    If there is no ball stuck, ensure that you are not leaning
                    on the trainer&apos;s head as it moves.
                </Typography>
                <img
                    src={cdnUrl("help-dialogs/tilt_calibration_help.jpg")}
                    alt="Ball jam location"
                    style={{ width: "100%", marginTop: "8px" }}
                />
            </DialogContent>
            <DialogActions>
                <Stack width="100%">
                    <LoadingButton
                        variant="contained"
                        color="secondary"
                        fullWidth
                        loading={loweringPower}
                        onClick={onClearLowerPower}
                        sx={{ mb: 2 }}
                    >
                        Proceed
                    </LoadingButton>
                    <Button
                        onClick={() => {
                            intercom.newMessage();
                        }}
                        startIcon={<HelpIcon />}
                    >
                        Need help? Chat with support.
                    </Button>
                </Stack>
            </DialogActions>
        </>
    );
}
