import * as React from "react";

import { hasTiltCalibrationFailure } from "../../../util/failures";
import { useStatus } from "../../hooks/status";
import { useTrainerFeatures } from "../../hooks/useTrainerFeatures";
import PowerUpDialog from "../PowerUpDialog";
import TiltCalibrationFailureError from "../TiltCalibrationFailureError";
import TiltCalibrationFailurePreamble from "../TiltCalibrationFailurePreamble";
import TiltCalibrationFailureRemoveBall from "../TiltCalibrationFailureRemoveBall";
import useDialog from "../useDialog";

const enum TiltCalibrationFailureSteps {
    Start,
    PoweredDown,
    PowerUp,
    Done,
    Error,
}

export default function TiltCalibrationFailureFlow(): JSX.Element | null {
    const { status } = useStatus();
    const features = useTrainerFeatures();
    const { setDialogType, setTiltCalibrationDismissed } = useDialog();
    const [step, setStep] = React.useState<TiltCalibrationFailureSteps>(
        TiltCalibrationFailureSteps.Start,
    );

    switch (step) {
        case TiltCalibrationFailureSteps.Start:
            return (
                <TiltCalibrationFailurePreamble
                    onPoweredDown={() =>
                        setStep(TiltCalibrationFailureSteps.PoweredDown)
                    }
                    onError={() => {
                        setStep(TiltCalibrationFailureSteps.Error);
                    }}
                />
            );
        case TiltCalibrationFailureSteps.PoweredDown:
            return (
                <TiltCalibrationFailureRemoveBall
                    onBallRemoved={() =>
                        setStep(TiltCalibrationFailureSteps.PowerUp)
                    }
                />
            );
        case TiltCalibrationFailureSteps.PowerUp:
            return (
                <PowerUpDialog
                    onPoweredUp={() =>
                        setStep(TiltCalibrationFailureSteps.Done)
                    }
                    onError={() => {
                        // PowerUpDialog calls this if it detects a fault
                        // Check if we have a tilt calibration failure, if so
                        // reset to the start and the user can either cancel
                        // the flow or try again.
                        if (
                            hasTiltCalibrationFailure(status?.fault, features)
                        ) {
                            setStep(TiltCalibrationFailureSteps.Start);
                        } else {
                            // if not a tilt calibration failure, mark the flow
                            // as dismissed and show the device dialog
                            setTiltCalibrationDismissed(true);
                            setDialogType("Device");
                        }
                    }}
                    autoCalibrate
                />
            );
        case TiltCalibrationFailureSteps.Error:
            return <TiltCalibrationFailureError />;
        case TiltCalibrationFailureSteps.Done:
            setDialogType(null);
    }

    return null;
}
