import * as React from "react";

import Battery1BarIcon from "@mui/icons-material/Battery1Bar";
import CircleIcon from "@mui/icons-material/Circle";
import ErrorIcon from "@mui/icons-material/Error";
import HelpIcon from "@mui/icons-material/Help";
import ReportIcon from "@mui/icons-material/Report";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { formatDistance } from "date-fns";

import { CoachStatus } from "@volley/shared/coach-models";
import type { ProximityDetectionConfig } from "@volley/shared/vision-models";

import fetchApi, {
    logFetchError,
    pairedFetchApi,
} from "../../../util/fetchApi";
import CellularStatusIcon from "../../common/CellularStatusIcon";
import WifiStatusIcon from "../../common/WifiStatusIcon";
import { useCurrentUser } from "../../hooks/currentUser";
import { useStatus } from "../../hooks/status";
import useIntercom from "../../hooks/useIntercom";
import Status from "../Status/Status";
import useDialog from "../useDialog";

import AboutListItem from "./AboutListItem";
import BallBinListItem from "./BallBinListItem";
import BatteryListItem from "./BatteryListItem";
import GestureControlListItem from "./GestureControlListItem";
import HeadUnitListItem from "./HeadUnitListItem";
import ObstructionListItem from "./ObstructionListItem";
import WifiListItem from "./WifiListItem";

enum StatusType {
    Advanced,
    Standard,
}

function DeviceDialogStatusIcon({
    status,
}: {
    status: CoachStatus | null;
}): JSX.Element {
    // If there's no status, or the status is a fault, show an error icon
    if (!status || status.fault) {
        // Handle special case of low battery
        if (status?.fault?.failures.some((f) => f.type === "LowBattery")) {
            return <Battery1BarIcon color="error" fontSize="medium" />;
        }

        if (status?.fault?.failures.every((f) => f.source === "vision")) {
            // If only vision faults, don't show an error icon
            return <CircleIcon color="success" fontSize="small" />;
        }

        return <ErrorIcon color="error" fontSize="small" />;
    }

    // If the status is not faulted, show a green circle
    return <CircleIcon color="success" fontSize="small" />;
}

export default function DeviceDialog(): JSX.Element {
    const intercom = useIntercom();
    const { setDialogType, setDrawerHeight } = useDialog();
    const { status } = useStatus();
    const { isAdmin, features } = useCurrentUser();
    const [statusType, setStatusType] = React.useState(StatusType.Standard);
    const [configRequested, setConfigRequested] = React.useState(false);
    const [proximityEnabled, setProximityEnabled] =
        React.useState<boolean>(false);
    const [gestureAvailable, setGestureAvailable] =
        React.useState<boolean>(false);
    const [previousSessionUser, setPreviousSessionUser] = React.useState<
        string | null
    >(null);

    const onStatusTypeClick = React.useCallback(() => {
        setStatusType((current) =>
            current === StatusType.Standard
                ? StatusType.Advanced
                : StatusType.Standard,
        );
    }, []);

    React.useEffect(() => {
        async function fetchData() {
            if (status?.clientId) {
                const show = await pairedFetchApi<string[]>(
                    status.clientId,
                    "/api/features",
                );
                setGestureAvailable(show.includes("gestures"));
            }
        }

        fetchData().catch((e) => {
            logFetchError(e);
        });
    }, [status?.clientId, setGestureAvailable]);

    React.useEffect(() => {
        let shortHeight = 560;

        if (isAdmin() && proximityEnabled) {
            shortHeight += 80;
        }

        if (features.includes("USER_UPDATE")) {
            shortHeight += 80;
        }

        setDrawerHeight(
            statusType === StatusType.Standard ? `${shortHeight}px` : "100vh",
        );
    }, [features, statusType, setDrawerHeight, isAdmin, proximityEnabled]);

    React.useEffect(() => {
        async function fetchProximityConfig() {
            const config = await pairedFetchApi<ProximityDetectionConfig>(
                status?.clientId,
                "/api/proximity/config",
            );
            return config.enabled;
        }

        if (!configRequested && isAdmin()) {
            setConfigRequested(true);
            fetchProximityConfig()
                .then((enabled) => setProximityEnabled(enabled))
                .catch((e) =>
                    logFetchError(
                        e,
                        "Error loading proximity config status for DeviceDialog",
                    ),
                );
        }
    }, [status?.clientId, configRequested, isAdmin]);

    React.useEffect(() => {
        async function fetchPreviousUser(clientId: number) {
            return await fetchApi<{ username: string; date: string }>(
                `/api/sessions/previous/trainer/${clientId}`,
            );
        }
        if (status?.clientId) {
            fetchPreviousUser(status.clientId)
                .then((username) => {
                    const date = new Date(username.date);
                    const ago = formatDistance(date, new Date(), {
                        addSuffix: true,
                    });
                    setPreviousSessionUser(`${username.username} (${ago})`);
                })
                .catch((e) => logFetchError(e));
        }
    }, [status?.clientId]);

    return (
        <Box
            component="div"
            pt={{ sm: 8, xs: 6 }}
            ml={{ sm: "240px" }}
            width={{ sm: "calc(100% - 240px)" }}
        >
            {isAdmin() && (
                <Button onClick={onStatusTypeClick} fullWidth sx={{ mt: 1 }}>
                    {statusType === StatusType.Standard
                        ? "Advanced Status >"
                        : "< Device Status"}
                </Button>
            )}
            {statusType === StatusType.Advanced ? (
                <Status />
            ) : (
                <Stack sx={{ p: 2 }} spacing={0}>
                    <Stack direction="row" justifyContent="space-between">
                        <Stack direction="row" alignItems="center">
                            <DeviceDialogStatusIcon status={status} />
                            <Typography variant="h3" pl={1}>
                                {`Trainer: ${status?.clientId ?? ""}`}
                            </Typography>
                        </Stack>
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Box component="div">
                                <CellularStatusIcon
                                    cellularStatus={
                                        status?.system.cellular ?? null
                                    }
                                />
                            </Box>
                            <Box component="div">
                                <WifiStatusIcon
                                    wifiStatus={status?.system.wifi ?? null}
                                />
                            </Box>
                        </Stack>
                    </Stack>
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Stack>
                            {!!status?.session && (
                                <Typography>
                                    <Typography component="span" variant="h3">
                                        {`${status.session?.users.map((u) => u.name).join(", ") ?? ""}`}
                                    </Typography>
                                </Typography>
                            )}
                            {previousSessionUser && (
                                <Stack>
                                    <Typography variant="caption">
                                        Previous session:
                                    </Typography>
                                    <Typography variant="caption">
                                        {previousSessionUser}
                                    </Typography>
                                </Stack>
                            )}
                        </Stack>
                        <Button
                            variant="contained"
                            color="secondary"
                            size="large"
                            onClick={() => setDialogType("Finished")}
                        >
                            End Session
                        </Button>
                    </Stack>

                    <List
                        sx={{
                            my: 0.5,
                            "& .MuiListItem-root": {
                                borderStyle: "solid",
                                borderWidth: 1,
                                borderColor: (t) => t.palette.grey[400],
                                borderBottomStyle: "none",
                                "&:last-child": {
                                    borderBottomStyle: "solid",
                                },
                            },
                        }}
                    >
                        {/* Head Unit */}
                        <HeadUnitListItem />

                        {/* Battery */}
                        <BatteryListItem />

                        {/* Ball Bin */}
                        <BallBinListItem />

                        {features.includes("WIFI-RESET") && <WifiListItem />}

                        {/* Gesture */}
                        {features.includes("GESTURE_CONTROL") &&
                            gestureAvailable && <GestureControlListItem />}

                        {/* Obstructions - admin only? */}
                        {isAdmin() && proximityEnabled && (
                            <ObstructionListItem />
                        )}

                        {/* About */}
                        {/* hidden behind feature flag until ready for wide release */}
                        {features.includes("USER_UPDATE") && <AboutListItem />}
                    </List>
                    <Stack direction="row" justifyContent="space-evenly">
                        <Button
                            onClick={() => {
                                intercom.newMessage();
                            }}
                            startIcon={<HelpIcon />}
                        >
                            Help
                        </Button>
                        <Button
                            onClick={() => setDialogType("ReportIssue")}
                            startIcon={<ReportIcon />}
                        >
                            Report an Issue
                        </Button>
                    </Stack>
                    <Typography variant="h3" textAlign="center">
                        Control Center
                    </Typography>
                </Stack>
            )}
        </Box>
    );
}
