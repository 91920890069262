import { Fault } from "@volley/shared/coach-models";

import logger from "../log";

interface MotionFault {
    type: string;
    failures: { name: string }[];
}

// This function checks for a specific fault about the the tilt servo failing to calibrate
// if this passes, we should initiate the TiltCalibrationFailure dialog workflow
export function hasTiltCalibrationFailure(
    fault: Fault | null | undefined,
    trainerFeatures: string[],
): boolean {
    if (!fault) {
        return false;
    }
    if (!trainerFeatures.includes("powerUpAutoCalibrate")) {
        logger.debug(
            "Trainer doesn't support required APIs for tilt calibration failure",
        );
        return false;
    }
    const match = fault.failures.find(
        (failure) =>
            Object.hasOwn(failure.context, "motionFault") &&
            (failure.context.motionFault as MotionFault).type ===
                "CalibrationRange" &&
            (failure.context.motionFault as MotionFault).failures.some(
                (f) => f.name === "Tilt",
            ),
    );
    if (!match) {
        return false;
    }

    logger.info("Found CalibrationRange failure for Tilt Servo");
    return true;
}
