import * as React from "react";
import { useParams, useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import {
    AppWorkoutWithSummary as AppWorkout,
    AppWorkoutCreatePayload,
} from "@volley/data";
import { convert } from "@volley/physics/dist";
import {
    LeveledWorkoutConfig,
    LeveledWorkoutLevel,
    LevelNumber,
    Levels,
} from "@volley/shared/apps/curated-workout-models";
import type { CuratedWorkoutConfig } from "@volley/shared/apps/curated-workout-models";
import type { JSONObject } from "@volley/shared/common-models";

import { pairedFetchApi } from "../../../../../util/fetchApi";
import { CoordLike, PositionLike } from "../../../../../util/position-types";
import { pluralize } from "../../../../../util/text";
import Loading from "../../../../common/Loading";
import {
    VisualizerShot,
    WorkoutForVisualizer,
} from "../../../../common/Visualizer/types";
import { useSelectedSport } from "../../../../common/context/sport";
import { usePhysicsModelContext } from "../../../../hooks/PhysicsModelProvider";
import { useStatus } from "../../../../hooks/status";
import { LiftModal, useLift } from "../../../../hooks/useLift";
import DetailsAccordion from "../../Accordions/Details";
import HeightAccordion from "../../Accordions/HeightAccordion";
import ParamsAccordion from "../../Accordions/ParamsAccordion";
import PlayerAccordion from "../../Accordions/Player";
import ShotsAccordion from "../../Accordions/Shots";
import TouchOnlyPositionAccordion from "../../Accordions/TouchOnlyPosition";
import WorkoutVisualizerAccordion from "../../Accordions/VisualizerAccordion";
import PublishButton from "../../ContentManagement/PublishButton";
import WorkoutDeleteConfirmation from "../../Shared/WorkoutDeleteConfirmation";
import useAppWorkouts, { appWorkoutToUpdate, isAppWorkout } from "../../db";
import ProblemsDialog from "../4-multi-shot/play/ProblemsDialog";
import makeSafeSpinLevel from "../util";

function newLevel(number: LevelNumber): LeveledWorkoutLevel {
    return {
        number,
        shots: [],
        shotCount: 60,
        interval: 3,
    };
}

export default function MultiLevelEdit(): JSX.Element {
    const { id, appId } = useParams<{ id: string; appId: string }>();
    const { status } = useStatus();
    const playState = status?.workouts.playState ?? "stopped";
    const { selected: selectedSport, getDefaultPosition } = useSelectedSport();
    const currentWorkoutId = status?.workouts.currentWorkout?.id;
    const trainerId = status?.clientId;
    const navigate = useNavigate();
    const { physicsModelName } = usePhysicsModelContext();
    const {
        error: workoutError,
        loading,
        addWorkout,
        deleteWorkout,
        getWorkout,
        updateWorkout,
    } = useAppWorkouts();
    const { liftRange, safeHeight, setHeight, stop } = useLift();
    const numericId = parseInt(id ?? "", 10);
    const numericAppId = parseInt(appId ?? "", 10);
    const [workout, setWorkout] = React.useState<
        AppWorkout | AppWorkoutCreatePayload | null
    >(null);
    const [level, setLevel] = React.useState<LevelNumber>(1);
    const [toDelete, setToDelete] = React.useState<AppWorkout | null>(null);
    const [workoutProblems, setWorkoutProblems] = React.useState<string[]>([]);
    const [paddingProblems, setPaddingProblems] = React.useState<string | null>(
        null,
    );
    type CompletionButton = "Save" | "Play" | "Publish" | null;

    const updateConfig = React.useCallback(
        (config: LeveledWorkoutConfig) => {
            if (workout) {
                const updated = {
                    ...(workout.config as CuratedWorkoutConfig),
                    ...config,
                };
                setWorkout({
                    ...workout,
                    config: updated as unknown as JSONObject,
                });

                const matches = config.levels[level].shots?.filter((s) => {
                    const levelInterval = config.levels[level].interval;
                    if (s.intervalOverride && levelInterval) {
                        return s.intervalOverride <= levelInterval;
                    }

                    return false;
                });

                if (matches?.length) {
                    const s = pluralize(matches.length, "shot");
                    const hasOrHave = matches.length === 1 ? "has" : "have";
                    setPaddingProblems(
                        `${matches.length} ${s} ${hasOrHave} a padded time that may need adjusted`,
                    );
                } else {
                    setPaddingProblems(null);
                }
            }
        },
        [workout, setWorkout, level],
    );

    const copyLevel = React.useCallback(
        (type: "shots" | "feed", fromLevel: LevelNumber) => {
            if (!workout?.config) return;
            const config = workout.config as unknown as LeveledWorkoutConfig;
            const toCopy = config.levels[fromLevel];

            let newLevelProperties: Partial<LeveledWorkoutLevel> = {};
            if (type === "shots") {
                newLevelProperties = {
                    shots: toCopy.shots.map((s) => ({ ...s })),
                    randomize: toCopy.randomize,
                };
            } else if (type === "feed") {
                newLevelProperties = {
                    interval: toCopy.interval,
                    shotCount: toCopy.shotCount,
                };
            }

            updateConfig({
                ...config,
                levels: {
                    ...config.levels,
                    [level]: {
                        ...config.levels[level],
                        ...newLevelProperties,
                    },
                },
            });
        },
        [workout, level, updateConfig],
    );

    const saveWorkout = React.useCallback(
        async (
            changed: AppWorkout | AppWorkoutCreatePayload,
            completionButton: CompletionButton = "Save",
            publishTo: number | null = null,
        ) => {
            const problems: string[] = [];
            const cc = changed.config as unknown as LeveledWorkoutConfig;

            if (
                Object.values(cc.levels).every(
                    (l) => l.shots === undefined || l.shots.length === 0,
                )
            ) {
                problems.push(
                    "There are no shots configured for this workout.",
                );
            }
            if (
                changed.positionHeight === undefined ||
                changed.positionHeight < liftRange.min ||
                changed.positionHeight > liftRange.max
            ) {
                const err = `The height is not set or is out the trainers range. [${liftRange.min}/${liftRange.max}]`;
                problems.push(err);
            }
            if (
                changed.positionX === undefined ||
                changed.positionY === undefined ||
                changed.positionYaw === undefined
            ) {
                const err = "The trainer position has not been set";
                problems.push(err);
            }
            if (changed.name === undefined || changed.name === "") {
                problems.push("The workout name has not been set.");
            }
            if (cc.playerPosition === undefined) {
                problems.push("The player position has not been set.");
            }

            if (problems.length > 0) {
                setWorkoutProblems(problems);
            } else {
                Levels.forEach((l) => {
                    const { shots: originalShots } = cc.levels[l];
                    const shots = originalShots.map((shot) => {
                        const { launchSpeed, spinLevel, spinDirection } = shot;
                        const safeSpinLevel = makeSafeSpinLevel(
                            spinLevel ?? 0,
                            spinDirection,
                            launchSpeed,
                        );
                        return {
                            ...shot,
                            spinLevel: safeSpinLevel,
                        };
                    });
                    cc.levels[l].shots = shots;
                });

                let workoutId;
                const publishToId =
                    completionButton === "Publish"
                        ? publishTo
                        : changed.contentProviderId;
                if (!isAppWorkout(changed)) {
                    const safeToSave = {
                        ...changed,
                        config: cc as unknown as JSONObject,
                    };
                    const updated = await addWorkout({
                        ...safeToSave,
                        contentProviderId: publishToId,
                    });
                    setWorkout(updated);
                    workoutId = updated?.id;
                } else {
                    const safeToSave = {
                        ...changed,
                        config: cc as unknown as JSONObject,
                    };
                    const asUpdate = appWorkoutToUpdate({
                        ...safeToSave,
                        contentProviderId: publishToId,
                    });
                    const updated = await updateWorkout(asUpdate);
                    setWorkout(updated);
                    workoutId = updated?.id;
                }
                if (completionButton === "Save") {
                    navigate(-1);
                }
                if (completionButton === "Play") {
                    if (workoutId) {
                        navigate(`../play/9/${workoutId}`, { replace: true });
                    } else {
                        navigate(-1); // unlikely fallback
                    }
                }
            }
        },
        [liftRange, addWorkout, updateWorkout, setWorkout, navigate],
    );

    const fetchWorkout = React.useCallback(async () => {
        const result = await getWorkout(numericId);
        if (result !== null) {
            const lvl = Levels.find(
                (l) =>
                    (result.config as unknown as LeveledWorkoutConfig).levels[l]
                        .shots?.length,
            );
            if (lvl) {
                setLevel(lvl);
            }
        }
        setWorkout(result);
    }, [numericId, getWorkout, setWorkout]);

    React.useEffect(() => {
        if (numericId) {
            void fetchWorkout();
        } else {
            // this is a new workout - store create payload in state
            let position = getDefaultPosition();
            if (selectedSport === "PLATFORM_TENNIS") {
                const converted = convert.physics2localization({
                    x: position.x,
                    y: position.y,
                    z: safeHeight * 0.0254,
                });
                position = { x: converted.x, y: converted.y, sys: "court" };
            }
            const newWorkout: AppWorkoutCreatePayload = {
                appId: numericAppId,
                appName: "Curated Workout",
                config: {
                    levels: Levels.reduce<
                        Record<LevelNumber, LeveledWorkoutLevel>
                    >(
                        (acc, l) => {
                            acc[l] = newLevel(l);
                            return acc;
                        },
                        {} as Record<LevelNumber, LeveledWorkoutLevel>,
                    ) as unknown as JSONObject,
                },
                contentProviderId: null,
                copiedFromAppWorkoutId: null,
                description: "",
                extendedData: null,
                name: "",
                overview: "",
                physicsModelName,
                positionHeight: safeHeight,
                positionX: position.x,
                positionY: position.y,
                positionYaw: 0,
                sport: {
                    name: selectedSport,
                },
                state: "BUILD",
            };
            setWorkout(newWorkout);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        numericId,
        numericAppId,
        physicsModelName,
        selectedSport,
        fetchWorkout,
    ]);

    const [expanded, setExpanded] = React.useState<string | false>("details");

    const handlePanelChange =
        (panel: string) => (_: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };

    const handleSaveClick = React.useCallback(
        async (completionButton: CompletionButton = "Save") => {
            if (workout !== null) {
                // TODO: Remove this after implementing logic to update parameters on un-pause in coach
                // If a user is editing a workout, we're assuming that they may play this workout
                // If there is a workout already running with this same ID, we should stop it so when the
                // user plays again, coach will use the updated parameters
                if (
                    playState !== "stopped" &&
                    isAppWorkout(workout) &&
                    currentWorkoutId === workout?.id &&
                    trainerId
                ) {
                    await pairedFetchApi(
                        trainerId,
                        "/api/apps/workouts/stop",
                        "POST",
                    );
                }

                await saveWorkout(workout, completionButton);
            }
        },
        [playState, currentWorkoutId, workout, trainerId, saveWorkout],
    );

    const handleDeleteConfirmed = React.useCallback(async () => {
        if (!loading && toDelete?.id !== undefined) {
            setToDelete(null);
            await deleteWorkout(toDelete);
        }
        setWorkout(null);
        navigate("/content/apps/workouts/user", { replace: true });
    }, [loading, toDelete, deleteWorkout, navigate]);

    const selectedPosition = React.useMemo<PositionLike | undefined>(() => {
        if (workout === null) {
            return undefined;
        }

        if (
            workout.positionX === undefined &&
            workout.positionY === undefined &&
            workout.positionYaw === undefined
        ) {
            return undefined;
        }

        return {
            x: workout.positionX ?? 6096,
            y: workout.positionY ?? 13411,
            yaw: workout.positionYaw ?? 0,
        };
    }, [workout]);

    const workoutForVisualizer: WorkoutForVisualizer = React.useMemo(() => {
        const defaultPosition = {
            player: [] as CoordLike[],
            trainer: {
                x: 0,
                y: 0,
                yaw: 0,
                heightIn: liftRange.min,
            },
            shots: [] as VisualizerShot[],
        };

        if (workout === null) {
            return defaultPosition;
        }

        defaultPosition.trainer = {
            x: workout.positionX ?? 0,
            y: workout.positionY ?? 0,
            heightIn: workout.positionHeight ?? liftRange.min,
            yaw: workout.positionYaw ?? 0,
        };

        const config = workout.config as unknown as LeveledWorkoutConfig;

        if (config === undefined) {
            return defaultPosition;
        }

        const { playerPosition, levels } = config;
        const { shots } = levels[level];

        if (playerPosition) {
            defaultPosition.player = [playerPosition];
        }

        if (shots) {
            defaultPosition.shots = shots.map((s) => ({
                launchSpeed: s.launchSpeed,
                pan: s.pan,
                spinDirection: s.spinDirection,
                tilt: s.tilt,
                spinLevel: s.spinLevel,
                spinSpeed: s.spinSpeed,
            }));
        }

        return defaultPosition;
    }, [liftRange, workout, level]);

    const plannedPosition = React.useMemo(() => {
        // explicit check for undefined on yaw because yaw is very commonly 0
        if (
            !workout?.positionX ||
            !workout.positionY ||
            workout.positionYaw === undefined
        ) {
            return undefined;
        }

        return {
            x: workout.positionX,
            y: workout.positionY,
            yaw: workout.positionYaw,
        };
    }, [workout]);

    // Potential network error loading workout, let user retry or go back
    if (workout === null && workoutError !== null) {
        return (
            <Stack spacing={4} textAlign="center">
                <Typography variant="h3">
                    There was an error loading your workout:
                </Typography>
                <Typography variant="h4">{workoutError}</Typography>
                <Button
                    fullWidth
                    color="secondary"
                    variant="contained"
                    onClick={() => window.location.reload()}
                >
                    Reload to Try Again
                </Button>
                <Button
                    fullWidth
                    variant="contained"
                    onClick={() => navigate("../", { relative: "route" })}
                >
                    Back to Workout List
                </Button>
            </Stack>
        );
    }

    // No error loading, but workout isn't loaded yet, show loading
    if (workout === null && workoutError === null) {
        return <Loading />;
    }

    if (workout === null) {
        return (
            <Stack spacing={4} textAlign="center">
                <Typography variant="h3">
                    There was an unexpected error loading your workout.
                </Typography>
                <Button
                    fullWidth
                    variant="contained"
                    onClick={() =>
                        navigate("../", { replace: true, relative: "route" })
                    }
                >
                    Back to Workout List
                </Button>
            </Stack>
        );
    }

    const config = workout.config as unknown as LeveledWorkoutConfig;
    const levelConfig = config.levels[level];

    return (
        <Stack>
            <DetailsAccordion
                expanded={expanded === "details"}
                onChange={handlePanelChange("details")}
                name={workout.name ?? ""}
                onNameChanged={(n) =>
                    setWorkout({
                        ...workout,
                        name: n,
                    })
                }
                overview={workout.overview ?? ""}
                onOverviewChanged={(o) =>
                    setWorkout({
                        ...workout,
                        overview: o,
                    })
                }
            />
            <TouchOnlyPositionAccordion
                expanded={expanded === "position"}
                onChange={handlePanelChange("position")}
                selectedPosition={selectedPosition}
                onPositionChanged={(p) =>
                    setWorkout({
                        ...workout,
                        positionX: p.x,
                        positionY: p.y,
                        positionYaw: p.yaw,
                    })
                }
            />
            <PlayerAccordion
                expanded={expanded === "player"}
                onChange={handlePanelChange("player")}
                selectedTrainerPosition={selectedPosition}
                selectedPlayerPosition={config.playerPosition}
                onPlayerPositionChanged={(p) =>
                    updateConfig({
                        ...config,
                        playerPosition: p,
                    })
                }
            />
            <HeightAccordion
                expanded={expanded === "height"}
                onChange={handlePanelChange("height")}
                selectedHeight={workout.positionHeight ?? safeHeight}
                onHeightChanged={(h) =>
                    setWorkout({
                        ...workout,
                        positionHeight: h,
                    })
                }
                moveToHeight={() =>
                    setHeight(workout.positionHeight ?? safeHeight)
                }
            />
            <ShotsAccordion
                defaultInterval={levelConfig.interval ?? 2}
                plannedPosition={plannedPosition}
                height={workout.positionHeight ?? safeHeight}
                expanded={expanded === "shots"}
                onChange={handlePanelChange("shots")}
                selectedShots={levelConfig.shots ?? []}
                onSelectedShotsChanged={(shots) =>
                    updateConfig({
                        ...config,
                        levels: {
                            ...config.levels,
                            [level]: {
                                ...levelConfig,
                                shots,
                            },
                        },
                    })
                }
                randomize={levelConfig.randomize ?? false}
                onRandomizeChange={(randomize) =>
                    updateConfig({
                        ...config,
                        levels: {
                            ...config.levels,
                            [level]: {
                                ...levelConfig,
                                randomize,
                            },
                        },
                    })
                }
                level={level}
                setLevel={setLevel}
                copyLevel={copyLevel}
            />
            <ParamsAccordion
                expanded={expanded === "feed"}
                onChange={handlePanelChange("feed")}
                selectedInterval={levelConfig.interval ?? 2}
                onIntervalChanged={(i) =>
                    updateConfig({
                        ...config,
                        levels: {
                            ...config.levels,
                            [level]: {
                                ...levelConfig,
                                interval: i,
                            },
                        },
                    })
                }
                selectedShotCount={levelConfig.shotCount ?? 20}
                onShotCountChanged={(s) =>
                    updateConfig({
                        ...config,
                        levels: {
                            ...config.levels,
                            [level]: {
                                ...levelConfig,
                                shotCount: s,
                            },
                        },
                    })
                }
                level={level}
                setLevel={setLevel}
                copyLevel={copyLevel}
                validationError={paddingProblems ?? undefined}
            />
            <WorkoutVisualizerAccordion
                expanded={expanded === "Visualizer"}
                onChange={handlePanelChange("Visualizer")}
                trainerPosition={workoutForVisualizer.trainer}
                playerPosition={workoutForVisualizer.player[0]}
                playMode="standard"
                selectedSport={selectedSport}
                shots={workoutForVisualizer.shots}
            />
            <Box
                component="div"
                sx={{
                    marginTop: "15px",
                    pb: 12,
                }}
            >
                <Stack spacing={2}>
                    <Button
                        color="secondary"
                        onClick={() => handleSaveClick("Play")}
                        variant="contained"
                        fullWidth
                    >
                        Play
                    </Button>
                    <PublishButton
                        workout={workout}
                        saveFunction={(publishId) =>
                            saveWorkout(workout, "Publish", publishId)
                        }
                    />
                    <Button
                        color="info"
                        onClick={() => handleSaveClick()}
                        variant="contained"
                        fullWidth
                    >
                        Save & Close
                    </Button>
                    <Button
                        onClick={() => {
                            if (!isAppWorkout(workout)) {
                                navigate(-1);
                            } else {
                                setToDelete(workout);
                            }
                        }}
                        variant="contained"
                        color="primary"
                        fullWidth
                    >
                        {workout && isAppWorkout(workout)
                            ? "Delete Workout"
                            : "Cancel Creation"}
                    </Button>
                </Stack>
            </Box>
            <LiftModal
                targetHeight={workout.positionHeight ?? safeHeight}
                stop={() => stop()}
            />
            <ProblemsDialog
                open={workoutProblems.length > 0}
                problems={workoutProblems}
                cancel={() => setWorkoutProblems([])}
            />
            <WorkoutDeleteConfirmation
                workout={toDelete}
                onCancel={() => setToDelete(null)}
                onConfirm={() => handleDeleteConfirmed()}
            />
        </Stack>
    );
}
