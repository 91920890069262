import * as React from "react";

import LoadingButton from "@mui/lab/LoadingButton/LoadingButton";
import Checkbox from "@mui/material/Checkbox";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { IssueReportCreateJson, IssueType } from "@volley/data";

import fetchApi, { logFetchError } from "../../util/fetchApi";
import CloseableDialogTitle from "../common/CloseableDialogTitle";
import { usePairingContext } from "../hooks/pairingStatus";

import useDialog from "./useDialog";

const MIN_COMMENT_LENGTH = 5;

interface Props {
    onSubmitted: () => void;
    onError: () => void;
}

export default function ReportIssueDialog({
    onSubmitted,
    onError,
}: Props): JSX.Element {
    const { setDialogType } = useDialog();
    const [issues, setIssues] = React.useState<IssueType[]>([]);
    const { sessionId } = usePairingContext();
    const [userIssues, setUserIssues] = React.useState<number[]>([]);
    const [comment, setComment] = React.useState("");
    const [otherExplanationRequired, setOtherExplanationRequired] =
        React.useState(false);
    const [otherExplanation, setOtherExplanation] = React.useState("");
    const [submitting, setSubmitting] = React.useState(false);

    React.useEffect(() => {
        async function fetchIssueTypes() {
            return await fetchApi<IssueType[]>(
                "/api/issue-reports/types",
                "GET",
            );
        }

        fetchIssueTypes()
            .then((data) => {
                setIssues(data);
            })
            .catch((e) => {
                logFetchError(e, "Failed to fetch issue types");
                onError();
            });
    }, [onError]);

    const handleSave = async () => {
        setSubmitting(true);

        try {
            // post to save
            if (!sessionId) {
                throw new Error("Session ID is undefined");
            }

            const otherIssue = issues.find((i) => i.name === "other");
            const issueReport: IssueReportCreateJson = {
                comment: comment || null,
                issueReportIssues: userIssues.map((issueId) => ({
                    issueDescription:
                        issueId === otherIssue?.id ? otherExplanation : null,
                    issueTypeId: issueId,
                })),
                session: { id: sessionId },
            };
            await fetchApi("/api/issue-reports", "POST", issueReport);
            onSubmitted();
        } catch (e) {
            logFetchError(e, "Failed to submit user issue");
            onError();
        }
    };

    const submitDisabled = React.useMemo(() => {
        if (userIssues.length === 0) {
            return true;
        }
        if (comment.length > 0 && comment.length < 5) {
            return true;
        }
        const otherIssue = issues.find((i) => i.name === "other");
        if (
            userIssues.includes(otherIssue?.id ?? -1) &&
            otherExplanation.length < 5
        ) {
            return true;
        }
        return false;
    }, [userIssues, comment.length, issues, otherExplanation.length]);

    return (
        <>
            <CloseableDialogTitle onClose={() => setDialogType("Device")}>
                Report an Issue
            </CloseableDialogTitle>
            <DialogContent>
                <Stack spacing={1}>
                    <FormGroup>
                        {issues.map((issue) => (
                            <FormControlLabel
                                key={issue.id}
                                disableTypography
                                control={
                                    <Checkbox
                                        sx={{ p: 0.5 }}
                                        checked={userIssues.includes(issue.id)}
                                        onChange={(e) => {
                                            if (e.target.checked) {
                                                if (issue.name === "other") {
                                                    setOtherExplanationRequired(
                                                        true,
                                                    );
                                                }
                                                setUserIssues([
                                                    ...userIssues,
                                                    issue.id,
                                                ]);
                                            } else {
                                                if (issue.name === "other") {
                                                    setOtherExplanationRequired(
                                                        false,
                                                    );
                                                    setOtherExplanation("");
                                                }
                                                setUserIssues(
                                                    userIssues.filter(
                                                        (i) => i !== issue.id,
                                                    ),
                                                );
                                            }
                                        }}
                                    />
                                }
                                label={
                                    <Typography variant="body2">
                                        {issue.label}
                                    </Typography>
                                }
                            />
                        ))}
                    </FormGroup>
                    {otherExplanationRequired && (
                        <TextField
                            id="other-explanation"
                            label="Please explain the issue"
                            type="text"
                            fullWidth
                            multiline
                            rows={1}
                            value={otherExplanation}
                            onChange={(e) =>
                                setOtherExplanation(e.target.value)
                            }
                            error={otherExplanation.length < MIN_COMMENT_LENGTH}
                            helperText={
                                otherExplanation.length < MIN_COMMENT_LENGTH
                                    ? `Explanation must be at least ${MIN_COMMENT_LENGTH} characters`
                                    : undefined
                            }
                            required
                        />
                    )}
                    <TextField
                        id="comment"
                        label="Additional Comments"
                        type="text"
                        fullWidth
                        multiline
                        rows={3}
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        error={
                            comment.length > 0 &&
                            comment.length < MIN_COMMENT_LENGTH
                        }
                        helperText={
                            comment.length > 0 &&
                            comment.length < MIN_COMMENT_LENGTH
                                ? `Comments must be at least ${MIN_COMMENT_LENGTH} characters`
                                : undefined
                        }
                    />
                </Stack>
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    onClick={handleSave}
                    color="secondary"
                    variant="contained"
                    disabled={submitDisabled}
                    loading={submitting}
                >
                    Submit
                </LoadingButton>
            </DialogActions>
        </>
    );
}
