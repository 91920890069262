import * as React from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Stack from "@mui/material/Stack";

import { AppWorkoutWithSummary as AppWorkout } from "@volley/data";

interface WorkoutCopyConfirmationProps {
    workout: AppWorkout | null;
    onCancel: () => void;
    onConfirm: () => void;
}

export default function WorkoutCopyConfirmation({
    workout,
    onCancel,
    onConfirm,
}: WorkoutCopyConfirmationProps): JSX.Element {
    const handleCopyClicked = React.useCallback(() => {
        if (workout === null) {
            onCancel();
        } else {
            onConfirm();
        }
    }, [workout, onCancel, onConfirm]);
    return (
        <Dialog open={workout !== null}>
            <DialogTitle>Copy to My Library</DialogTitle>
            <DialogContent>
                {`Would you like to add a copy of "${workout?.name ?? "Unnamed"}" to your library?`}
            </DialogContent>
            <DialogActions>
                <Stack direction="row">
                    <Box
                        component="div"
                        sx={{
                            flexBasis: "50%",
                            padding: "0 5px 0 5px",
                        }}
                    >
                        <Button
                            fullWidth
                            onClick={() => onCancel()}
                            variant="contained"
                        >
                            Cancel
                        </Button>
                    </Box>
                    <Box
                        component="div"
                        sx={{
                            flexBasis: "50%",
                            padding: "0 5px 0 5px",
                        }}
                    >
                        <Button
                            fullWidth
                            onClick={() => handleCopyClicked()}
                            variant="contained"
                            color="secondary"
                        >
                            Copy
                        </Button>
                    </Box>
                </Stack>
            </DialogActions>
        </Dialog>
    );
}
