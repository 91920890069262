import { OffsetResult, AppWorkoutListItem } from "@volley/data";

import logger from "../log";
import fetchApi from "../util/fetchApi";

import FilterState, {
    type Filter,
} from "./Trainer/AppWorkouts/apps/shared/filterState";
import { Sport } from "./common/context/sport";

export interface FetchCriteria {
    trainerId?: number;
    limit: number;
    offset: number;
    sport: string;
}

export interface HomeScreenCategoryDefinition {
    sports: Sport[];
    fetchResults: (fetchCriteria: FetchCriteria) => Promise<HomeScreenCategory>;
}

export type ViewFormat = "list" | "grid";

export interface HomeScreenCategory {
    name: string;
    label: string;
    order: number;
    results: OffsetResult<AppWorkoutListItem>;
    showIfEmpty?: boolean;
    navUrl?: string;
    setFilter?: () => void;
}

const defaultFilters: Filter = {
    appId: null,
    position: false,
    providerIds: [],
    tagIds: [],
};

export const categories: HomeScreenCategoryDefinition[] = [
    {
        sports: ["PADEL", "PICKLEBALL", "PLATFORM_TENNIS", "TENNIS"],
        fetchResults: async ({ sport, limit, offset }: FetchCriteria) => {
            const results = await fetchApi<OffsetResult<AppWorkoutListItem>>(
                `/api/app-workouts/app/11?sport=${sport}&limit=${limit}&offset=${offset}`,
            );
            return {
                name: "skill_building",
                label: "Skill Building",
                order: 15,
                results,
                navUrl: "/content/apps/workouts/search",
                setFilter: () => {
                    FilterState.setFilter("search", {
                        ...defaultFilters,
                        appId: 11,
                    });
                },
            };
        },
    },
    {
        sports: ["PADEL", "PICKLEBALL", "PLATFORM_TENNIS", "TENNIS"],
        fetchResults: async ({ sport, limit, offset }: FetchCriteria) => {
            const results = await fetchApi<OffsetResult<AppWorkoutListItem>>(
                `/api/app-workouts/app/9?sport=${sport}&limit=${limit}&offset=${offset}`,
            );
            return {
                name: "multi_level",
                label: "Multi-Level Workouts",
                order: 10,
                results,
                navUrl: "/content/apps/workouts/search",
                setFilter: () => {
                    FilterState.setFilter("search", {
                        ...defaultFilters,
                        appId: 9,
                    });
                },
            };
        },
    },
    {
        sports: ["PADEL", "PICKLEBALL", "PLATFORM_TENNIS", "TENNIS"],
        fetchResults: async ({ sport, limit, offset }: FetchCriteria) => {
            const results = await fetchApi<OffsetResult<AppWorkoutListItem>>(
                `/api/app-workouts/app/4?sport=${sport}&limit=${limit}&offset=${offset}`,
            );
            return {
                name: "multi-shot",
                label: "Multi-Shot Workouts",
                order: 51,
                results,
                navUrl: "/content/apps/workouts/search",
                setFilter: () => {
                    const filterState = { ...defaultFilters, appId: 4 };
                    FilterState.setFilter("search", filterState);
                },
            };
        },
    },
    {
        sports: ["PADEL", "PICKLEBALL", "PLATFORM_TENNIS", "TENNIS"],
        fetchResults: async ({ sport, limit, offset }: FetchCriteria) => {
            const results = await fetchApi<OffsetResult<AppWorkoutListItem>>(
                `/api/app-workouts/app/5?sport=${sport}&limit=${limit}&offset=${offset}`,
            );
            return {
                name: "single_shot",
                label: "Single Shot Workouts",
                order: 50,
                results,
                navUrl: "/content/apps/workouts/search",
                setFilter: () => {
                    const filterState = { ...defaultFilters, appId: 5 };
                    FilterState.setFilter("search", filterState);
                },
            };
        },
    },
    {
        fetchResults: async ({ sport, limit, offset }: FetchCriteria) => {
            const results = await fetchApi<OffsetResult<AppWorkoutListItem>>(
                `/api/app-workouts/mine?sport=${sport}&limit=${limit}&offset=${offset}`,
            );
            return {
                name: "my_workouts",
                label: "My Workouts",
                order: 20,
                results,
                showIfEmpty: true,
                navUrl: "/content/apps/workouts/user",
            };
        },
        sports: ["PADEL", "PICKLEBALL", "PLATFORM_TENNIS", "TENNIS"],
    },
    {
        sports: ["PADEL", "PICKLEBALL", "PLATFORM_TENNIS", "TENNIS"],
        fetchResults: async ({ sport }: FetchCriteria) => {
            const results = await fetchApi<AppWorkoutListItem[]>(
                `/api/app-workouts/popular?sport=${sport}`,
            );
            return {
                name: "popular",
                label: "Popular",
                order: 25,
                results: {
                    count: results.length,
                    offset: 0,
                    result: results,
                },
            };
        },
    },
    {
        sports: ["PADEL", "PICKLEBALL", "PLATFORM_TENNIS", "TENNIS"],
        fetchResults: async ({ sport, limit, offset }: FetchCriteria) => {
            const results = await fetchApi<OffsetResult<AppWorkoutListItem>>(
                `/api/app-workouts/home-club?sport=${sport}&limit=${limit}&offset=${offset}`,
            );

            let label = "My Home Club";
            if (results.result.length > 0) {
                label = results.result[0].provider?.label ?? label;
            }

            return {
                label,
                name: "home_club",
                order: 35,
                results,
                navUrl: "/content/apps/workouts/search",
                setFilter: () => {
                    if (results.result.length > 0) {
                        const providerId = results.result[0].provider?.id;
                        if (providerId) {
                            FilterState.setFilter("search", {
                                ...defaultFilters,
                                providerIds: [providerId],
                            });
                        }
                    }
                },
            };
        },
    },
    {
        sports: ["PADEL", "PICKLEBALL", "PLATFORM_TENNIS", "TENNIS"],
        fetchResults: async ({
            trainerId,
            sport,
            limit,
            offset,
        }: FetchCriteria) => {
            if (!trainerId) {
                logger.info(
                    "No trainer ID, not fetching trainer club workouts",
                );
                return {
                    label: "Trainer Club",
                    name: "trainer_club",
                    order: 40,
                    navUrl: "/content/apps/workouts/search",
                    results: {
                        count: 0,
                        offset: 0,
                        result: [],
                    },
                };
            }
            logger.info("Fetching trainer club workouts");
            const results = await fetchApi<OffsetResult<AppWorkoutListItem>>(
                `/api/app-workouts/trainer-club/${trainerId}?sport=${sport}&limit=${limit}&offset=${offset}`,
            );

            let label = "The Trainer's Club";
            if (results.result.length > 0) {
                label = results.result[0].provider?.label ?? label;
            }

            return {
                label,
                name: "trainer_club",
                order: 40,
                results,
                setFilter: () => {
                    if (results.result.length > 0) {
                        const providerId = results.result[0].provider?.id;
                        if (providerId) {
                            FilterState.setFilter("search", {
                                ...defaultFilters,
                                providerIds: [providerId],
                            });
                        }
                    }
                },
            };
        },
    },
    {
        sports: ["PADEL", "PICKLEBALL", "PLATFORM_TENNIS", "TENNIS"],
        fetchResults: async ({ sport, limit, offset }: FetchCriteria) => {
            const results = await fetchApi<OffsetResult<AppWorkoutListItem>>(
                `/api/app-workouts/favorites/mine?sport=${sport}&limit=${limit}&offset=${offset}`,
            );
            return {
                name: "my_favorites",
                label: "My Favorites",
                order: 45,
                results,
                navUrl: "/content/apps/workouts/favorites",
            };
        },
    },
    {
        sports: ["PADEL", "PICKLEBALL", "PLATFORM_TENNIS", "TENNIS"],
        fetchResults: async ({ sport }: FetchCriteria) => {
            const results = await fetchApi<OffsetResult<AppWorkoutListItem>>(
                `/api/app-workouts/recent/mine?sport=${sport}`,
            );

            return {
                name: "history",
                label: "Recently Played",
                order: 30,
                viewAllLink: "/content/history-stats/mine",
                results,
            };
        },
    },
];
