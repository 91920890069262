import * as React from "react";
import { useNavigate } from "react-router-dom";

import "keen-slider/keen-slider.min.css";
import CircleIcon from "@mui/icons-material/Circle";
import Stack from "@mui/material/Stack";
import { type KeenSliderOptions, useKeenSlider } from "keen-slider/react";

import { type HomeScreenCategory } from "../HomeScreen";
import { getApp, type AppId } from "../Trainer/AppWorkouts/apps";

interface FeaturedItemsProps {
    category: HomeScreenCategory;
}

export default function FeaturedItems({
    category,
}: FeaturedItemsProps): JSX.Element {
    const number = category.results.result.length;

    const navigate = useNavigate();

    const [currentSlide, setCurrentSlide] = React.useState(0);

    const options = React.useRef<KeenSliderOptions>({
        slideChanged(slider) {
            setCurrentSlide(slider.track.details.rel);
        },
        slides: {
            number,
            origin: "auto",
            perView: 1,
        },
        initial: Math.floor(Math.random() * number),
        loop: true,
        dragSpeed: 1,
        rubberband: true,
        mode: "snap",
    });

    const [sliderRef, slider] = useKeenSlider(options.current);

    return (
        <Stack spacing={2}>
            <div ref={sliderRef} className="keen-slider">
                {category.results.result.map((item) => {
                    const app = getApp(item.appId as AppId);
                    return (
                        <div key={item.id} className="keen-slider__slide">
                            <app.HeroComponent
                                workout={item}
                                onClick={(_id, navigateUrl) =>
                                    navigate(navigateUrl)
                                }
                            />
                        </div>
                    );
                })}
            </div>
            {number > 1 && (
                <Stack direction="row" spacing={1} justifyContent="center">
                    {Array.from(Array(number)).map((_, index) => (
                        <CircleIcon
                            key={index}
                            sx={{
                                color:
                                    index === currentSlide
                                        ? "primary.light"
                                        : "grey.500",
                                cursor: "pointer",
                                fontSize: "12px",
                            }}
                            onClick={() => {
                                if (index === currentSlide) return;
                                slider.current?.moveToIdx(index);
                            }}
                        />
                    ))}
                </Stack>
            )}
        </Stack>
    );
}
