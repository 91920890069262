import * as React from "react";

import ErrorIcon from "@mui/icons-material/Error";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { OffsetResult } from "@volley/data";
import type {
    AppWorkoutWithSummary as AppWorkout,
    AppWorkoutListItem,
    ContentProvider,
} from "@volley/data";
import {
    setPhysicsModel,
    localization2physics,
} from "@volley/physics/dist/conversions";
import { PhysicsModelName } from "@volley/physics/dist/models";
import type { AppParameters } from "@volley/shared/app-models";
import type {
    ResponsiveState,
    ResponsiveParameters,
    DifficultyLevel,
    ResponsiveIndexEntry,
} from "@volley/shared/apps/responsive-models";
import { JSONObject } from "@volley/shared/common-models";

import logger from "../../../../../log";
import fetchApi, { logFetchError } from "../../../../../util/fetchApi";
import { PositionLike } from "../../../../../util/position-types";
import useDialog from "../../../../Dialog/useDialog";
import ResizableWorkoutVisualizer from "../../../../common/Visualizer/ResizableWorkoutVisualizer";
import {
    VisualizerTrainer,
    WorkoutForVisualizer,
} from "../../../../common/Visualizer/types";
import { useSelectedSport } from "../../../../common/context/sport";
import { usePhysicsModelContext } from "../../../../hooks/PhysicsModelProvider";
import { useStatus } from "../../../../hooks/status";
import { LiftModal, useLift } from "../../../../hooks/useLift";
import usePosition from "../../../../hooks/usePosition";
import CaptureToast from "../../Shared/CaptureToast";
import PlayAppBar from "../../Shared/PlayAppBar";
import SpeedAdjustment from "../../Shared/SpeedAdjustment";
import ThrowCount from "../../Shared/ThrowCount";
import useAppWorkouts from "../../db";
import useAppWorkoutPlay from "../../useAppWorkoutPlay";
import LocalizingDialog from "../10-serve-and-volley/LocalizingDialog";

import WorkoutPicker from "./WorkoutPicker";

type ParamsAction =
    | { type: "shots"; value: number }
    | { type: "speedAdjustment"; value?: number }
    | { type: "mode"; value: "follow" | "probability" }
    | { type: "difficulty"; value: DifficultyLevel };

function paramsReducer(
    state: ResponsiveParameters,
    action: ParamsAction,
): ResponsiveParameters {
    switch (action.type) {
        case "shots":
            return { ...state, numberOfBalls: action.value };
        case "speedAdjustment":
            return { ...state, speedAdjustment: action.value };
        case "difficulty":
            return { ...state, difficulty: action.value };
        case "mode":
            return { ...state, mode: action.value, difficulty: "hard" };
        default:
            throw new Error(
                "Invalid action to perform on a Responsive Parameters set.",
            );
    }
}

const DEFAULT_PARAMETERS: ResponsiveParameters = {
    numberOfBalls: 60,
    initialDelay: 5,
    difficulty: "hard",
    mode: "probability",
};

export default function ResponsivePlayApp(): JSX.Element {
    const [paramsDirty, setParamsDirty] = React.useState(false);
    const [paramsState, paramsDispatch] = React.useReducer(
        paramsReducer,
        DEFAULT_PARAMETERS,
    );
    const [loading, setLoading] = React.useState(false);
    const parameters = paramsState as unknown as AppParameters;
    const [workout, setWorkout] = React.useState<AppWorkout | null>(null);
    const { physicsModelName } = usePhysicsModelContext();
    const { selected: selectedSport } = useSelectedSport();
    const { position, isVisionFaulted, isVisionStarting, cancel } =
        usePosition();
    const { status } = useStatus();
    const visionUnavailable =
        isVisionStarting || status?.vision?.serviceState !== "Running";
    const [availableWorkouts, setAvailableWorkouts] = React.useState<
        AppWorkoutListItem[] | null
    >(null);
    const [playMode, setPlayMode] = React.useState<
        null | "planned" | "localized"
    >(null);

    const { setDialogType } = useDialog();

    const {
        addWorkout,
        loadResponsiveWorkout,
        loadResponsiveWorkouts,
        loadResponsiveWorkoutList,
        loadResponsiveWorkoutApp,
    } = useAppWorkouts();

    const { stop: stopLift } = useLift();

    const workoutForPlay = React.useMemo(() => {
        if (selectedSport !== "TENNIS" || playMode === "planned") {
            return workout;
        }

        if (workout && position && playMode === "localized") {
            logger.info(
                `[responsive play] - Modifying TENNIS workout based on localized position: ${JSON.stringify(position)}`,
            );
            const { positionHeight, positionX, positionY, positionYaw } =
                workout;
            logger.info(
                `[responsive play] - Original Workout Position: ${JSON.stringify({ positionHeight, positionX, positionY, positionYaw })}`,
            );
            setPhysicsModel(
                physicsModelName as PhysicsModelName,
                selectedSport,
            );

            const asPhysics = localization2physics({
                x: position.x,
                y: position.y,
                z: position.z,
            });

            const updated = {
                ...workout,
                positionX: asPhysics.x,
                positionY: asPhysics.y,
                positionYaw: position.yaw,
            };
            const {
                positionHeight: updatedHeight,
                positionX: updatedX,
                positionY: updatedY,
                positionYaw: updatedYaw,
            } = updated;
            logger.info(
                `[responsive play] - Updated Workout Position: ${JSON.stringify({ updatedHeight, updatedX, updatedY, updatedYaw })}`,
            );
            return updated;
        } else {
            return workout;
        }
    }, [workout, physicsModelName, position, selectedSport, playMode]);

    const {
        start,
        pause,
        stop: stopWorkout,
        captureDisabled,
        requestError,
        playInitiated,
        playDisabled,
        pauseDisabled,
        playState,
        workoutState,
        captureVideo,
        captureStatus,
    } = useAppWorkoutPlay({
        workout: workoutForPlay,
        parameters,
        localizedPosition:
            selectedSport === "PLATFORM_TENNIS"
                ? (position as PositionLike)
                : undefined,
    });

    async function fetchAndMigrateWorkouts() {
        logger.info(
            "[responsive play] Fetching responsive workout list format",
        );
        const workoutList = await loadResponsiveWorkoutList();
        if (workoutList.length === 0) {
            logger.info(
                "[responsive play] No published workouts found, attempting to load from old format",
            );
            const oldWorkouts = await loadResponsiveWorkouts();
            if (oldWorkouts.length === 0) {
                logger.info(
                    "[responsive play] No old workouts found, nothing to migrate",
                );
                return [];
            }
            logger.info(
                "[responsive play] Found old workouts, fetching VOLLEY provider info",
            );
            const providers = await fetchApi<OffsetResult<ContentProvider>>(
                "/api/content-providers?limit=100&offset=0",
            );
            const volleyProvider = providers.result.find(
                (p) => p.name === "VOLLEY",
            );
            if (volleyProvider === undefined) {
                logger.error(
                    "[responsive play] VOLLEY provider not found, cannot migrate workouts",
                );
                return [];
            }

            async function migrateWorkout(indexEntry: ResponsiveIndexEntry) {
                const workout = await loadResponsiveWorkout(
                    indexEntry.fileSuffix,
                );
                if (workout !== null) {
                    const { trainer, ...config } = workout;
                    logger.info(
                        `[responsive play] Migrating workout ${indexEntry.displayName}`,
                    );
                    logger.info(
                        `[responsive play] Saving workout ${JSON.stringify(config)}`,
                    );
                    await addWorkout({
                        appId: 2,
                        appName: "ResponsivePlay",
                        config: config as unknown as JSONObject,
                        description: "Responsive Play Workout",
                        name: `${indexEntry.displayName}`,
                        overview: "",
                        positionHeight: trainer.positionHeight,
                        positionX: trainer.positionX,
                        positionY: trainer.positionY,
                        positionYaw: 0,
                        state: "BUILD",
                        physicsModelName,
                        sport: { name: selectedSport },
                        contentProviderId: volleyProvider?.id || null,
                        copiedFromAppWorkoutId: null,
                        extendedData: null,
                    });
                }
            }
            const promises = oldWorkouts.map(migrateWorkout);
            const migrationResults = await Promise.allSettled(promises);
            migrationResults.forEach((result, i) => {
                if (result.status === "rejected") {
                    logger.error(
                        `[responsive play] Migration ${oldWorkouts[i].displayName} failed: ${result.reason}`,
                    );
                } else {
                    logger.info(
                        `[responsive play] Migration ${oldWorkouts[i].displayName} successful`,
                    );
                }
            });
            const migratedResults = await loadResponsiveWorkoutList();
            logger.info(
                `[responsive play] Migrated ${migratedResults.length} workouts`,
            );
            return migratedResults;
        } else {
            logger.info(
                "[responsive play] Found responsive workout list - no migration required",
            );
            return workoutList;
        }
    }

    React.useEffect(() => {
        setLoading(true);
        fetchAndMigrateWorkouts()
            .then((results) => {
                setAvailableWorkouts(results);
                return results;
            })
            .catch((e) =>
                logFetchError(
                    e,
                    "Failed to fetch list of responsive workouts.",
                ),
            )
            .finally(() => setLoading(false));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const responsiveWorkoutState =
        workoutState as unknown as ResponsiveState | null;
    const ballsThrown = responsiveWorkoutState?.currentShotNumber ?? 0;
    const totalBalls =
        (parameters as unknown as ResponsiveParameters).numberOfBalls ?? 0;
    const summaryText = playInitiated ? `${ballsThrown} of ${totalBalls}` : "";

    const handlePlayClicked = React.useCallback(async () => {
        if (paramsDirty && playState !== "stopped")
            stopWorkout().catch(logFetchError);
        if (workout && ["TENNIS", "PLATFORM_TENNIS"].includes(selectedSport)) {
            setLocalizingDialogOpen(true);
        } else {
            start();
        }
    }, [workout, selectedSport, start, paramsDirty, stopWorkout, playState]);

    const handleStopWorkout = React.useCallback(async () => {
        await stopWorkout();
    }, [stopWorkout]);

    const handleLiftStop = React.useCallback(async () => {
        await stopLift();
        if (playState !== "stopped" || playInitiated) {
            await handleStopWorkout();
        }
    }, [handleStopWorkout, playInitiated, playState, stopLift]);

    const [localizingDialogOpen, setLocalizingDialogOpen] =
        React.useState(false);
    const forceLocalizationRef = React.useRef(false);

    const levelOptions = [
        { value: "easy", label: "Easy" },
        { value: "medium", label: "Medium" },
        { value: "hard", label: "Difficult" },
    ];

    const plannedTrainerPosition: VisualizerTrainer | undefined =
        React.useMemo(() => {
            if (!workout) return undefined;
            return {
                x: workout.positionX,
                y: workout.positionY,
                yaw: workout.positionYaw,
                heightIn: workout.positionHeight,
            };
        }, [workout]);

    const workoutForVisualizer: WorkoutForVisualizer | undefined =
        React.useMemo(
            () =>
                localizingDialogOpen || !plannedTrainerPosition
                    ? undefined
                    : {
                          trainer: plannedTrainerPosition,
                          shots: [],
                          AOIs: [],
                          player: [],
                      },
            [localizingDialogOpen, plannedTrainerPosition],
        );

    const workoutForLocalizingDialog: WorkoutForVisualizer = React.useMemo(
        () => ({
            trainer: plannedTrainerPosition!,
            localized: position && {
                ...position,
                heightIn: plannedTrainerPosition?.heightIn || 0,
            },
            player: [],
            shots: [],
            AOIs: [],
        }),
        [plannedTrainerPosition, position],
    );

    React.useEffect(() => {
        if (playMode) {
            setPlayMode(null);
            start();
        }
    }, [playMode, start]);

    const onChangeSpeedAdjustment = React.useCallback((value?: number) => {
        paramsDispatch({
            type: "speedAdjustment",
            value,
        });
        setParamsDirty(true);
    }, []);

    return (
        <Stack
            sx={{
                backgroundColor: "background.default",
                mx: -1,
                p: 2,
                height: "90vh",
                pb: 16,
            }}
        >
            {plannedTrainerPosition && workoutForLocalizingDialog && (
                <LocalizingDialog
                    dialogOpen={localizingDialogOpen}
                    onLocalized={(result) => {
                        logger.info(`[responsive play] - Localized: ${result}`);
                        setLocalizingDialogOpen(false);
                        if (result === "good") {
                            forceLocalizationRef.current = false;
                            setPlayMode("localized");
                        } else {
                            forceLocalizationRef.current = true;
                        }
                    }}
                    onCanceled={() => {
                        logger.info(
                            `[responsive play] - Localization canceled by user`,
                        );
                        cancel();
                        setLocalizingDialogOpen(false);
                    }}
                    onUsePlanned={() => {
                        logger.info(
                            "[responsive play] - User chose to use planned position starting workout immediately",
                        );
                        setLocalizingDialogOpen(false);
                        forceLocalizationRef.current = false;
                        setPlayMode("planned");
                    }}
                    plannedPosition={plannedTrainerPosition}
                    force={forceLocalizationRef.current}
                    workout={workoutForLocalizingDialog}
                />
            )}
            <LiftModal stop={handleLiftStop} />
            {requestError && (
                <Typography color="error.main">{requestError}</Typography>
            )}
            {visionUnavailable && (
                <Typography color="info.main" variant="caption">
                    Play will be enabled when paired and cameras fully
                    initialized
                </Typography>
            )}
            <Typography variant="h3" color="primary.main">
                Responsive Play
            </Typography>
            <Stack spacing={2}>
                <WorkoutPicker
                    fullWidth
                    loading={loading}
                    disabled={playState === "playing"}
                    options={availableWorkouts ?? []}
                    selected={workout?.id ?? null}
                    onChange={(id) => {
                        setLoading(true);
                        loadResponsiveWorkoutApp(id)
                            .then((result) => {
                                // force localization even if there is a position cached.
                                forceLocalizationRef.current = true;
                                setWorkout(result);
                                setParamsDirty(false);
                                stopWorkout().catch(logFetchError);
                            })
                            .catch((e) => {
                                logFetchError(
                                    e,
                                    `Failed to load workout ${id}`,
                                );
                            })
                            .finally(() => setLoading(false));
                    }}
                />
                <Stack direction="row" spacing={2} alignItems="center">
                    <Typography
                        variant="caption"
                        sx={{
                            minWidth: "30px",
                        }}
                    >
                        Difficulty
                    </Typography>
                    <ButtonGroup
                        disabled={playState === "playing"}
                        fullWidth
                        size="small"
                        color="info"
                    >
                        {levelOptions.map((o) => (
                            <Button
                                key={o.value}
                                variant={
                                    paramsState.difficulty === o.value
                                        ? "contained"
                                        : "outlined"
                                }
                                size="small"
                                sx={{
                                    padding: "2px",
                                    color:
                                        paramsState.difficulty === o.value
                                            ? "common.white"
                                            : "primary.light",
                                    backgroundColor:
                                        paramsState.difficulty === o.value
                                            ? "primary.light"
                                            : "common.white",
                                    fontSize: "10px",
                                }}
                                onClick={() => {
                                    paramsDispatch({
                                        type: "difficulty",
                                        value: o.value as DifficultyLevel,
                                    });
                                    setParamsDirty(true);
                                }}
                            >
                                {o.label}
                            </Button>
                        ))}
                    </ButtonGroup>
                </Stack>
                <ThrowCount
                    disabled={playState === "playing"}
                    selectedThrowCount={paramsState.numberOfBalls}
                    onUserThrowCountChanged={(count) => {
                        paramsDispatch({ type: "shots", value: count });
                        setParamsDirty(true);
                    }}
                />
                {workoutForPlay && (
                    <SpeedAdjustment
                        disabled={playState === "playing"}
                        value={paramsState.speedAdjustment}
                        sport={selectedSport}
                        cacheKey="responsive-play"
                        onChange={onChangeSpeedAdjustment}
                    />
                )}
                {status && isVisionFaulted && (
                    <Button
                        variant="text"
                        startIcon={<ErrorIcon />}
                        onClick={() =>
                            setDialogType("VisionFaultServeAndVolleyDialog")
                        }
                        color="error"
                    >
                        <Typography variant="h4">
                            Camera system unavailable
                        </Typography>
                    </Button>
                )}
                {workoutForVisualizer && plannedTrainerPosition && (
                    <ResizableWorkoutVisualizer
                        workout={workoutForVisualizer}
                        positionProximity="Unavailable"
                        maxHeight={225}
                    />
                )}
            </Stack>

            <PlayAppBar
                onPauseClicked={() => pause()}
                onPlayClicked={() => handlePlayClicked()}
                pauseDisabled={pauseDisabled}
                playDisabled={
                    visionUnavailable || playDisabled || workout === null
                }
                playState={playState}
                showRecord
                onRecordClicked={() => captureVideo()}
                playSummary={summaryText}
                recordDisabled={visionUnavailable || captureDisabled}
                recordingStatus={captureStatus}
            />

            <CaptureToast captureStatus={captureStatus} />
        </Stack>
    );
}
